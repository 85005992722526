import React from 'react';
import styled, { keyframes } from 'styled-components';
import { scrollToRef } from '../../utils';
import { ReactComponent as ChevronDownIcon } from '../../resources/icons/chevron_down.svg';

const upDown = keyframes`
  0% {
    transform: translateY(-15%);
  }

  50% {
    transform: translateY(15%);
  }

  100% {
    transform: translateY(-15%);
  }
`;

export const ChevronDown = styled(ChevronDownIcon)`
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  animation: 2s ${upDown} ease infinite;
  z-index: 3;
`;

const ScrollDown = props => {
  const { sectionRef } = props;
  return <ChevronDown onClick={() => scrollToRef(sectionRef)} />;
};

export default ScrollDown;
