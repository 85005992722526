import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from './Button';
import { tabletWidth, device } from '../../devices';
import { useWindowSize } from '../../hooks';
import { useTranslation } from 'react-i18next';

const StyledButton = styled(Button)`
  @media ${device.tablet} {
    margin-top: 24px;
    padding: 12px;
  }
`;

const ButtonMore = props => {
  const { onClick, enableLongText, wide } = props;
  const { t } = useTranslation();
  const shortText = t('components.buttonMore.short');
  const longText = t('components.buttonMore.long');
  const [text, setText] = useState(shortText);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width >= tabletWidth && enableLongText) {
      setText(longText);
    } else {
      setText(shortText);
    }
  }, [width, enableLongText, shortText, longText]);

  return (
    <StyledButton wide={wide} onClick={onClick}>
      {text}
    </StyledButton>
  );
};

export default ButtonMore;
