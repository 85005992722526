import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import theme from '../../../theme';

const labelStyle = muiTheme => ({
  root: {
    color: `${theme.color.black}`,
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Poppins, serif'
    }
  },
  checked: {}
});

export default withStyles(labelStyle)(MuiFormControlLabel);
