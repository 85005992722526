import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitleUnderlined, CategoryLabel, Reveal } from '../../../components';
import {
  TwoColContainer,
  TwoCol,
  ThreeColContainer,
  ThreeCol
} from '../styles';
import { revealTimeoutNext } from '../../../utils';
import passiveHouseImage from '../../../resources/activities/passive-house.jpg';
import { ReactComponent as PrinciplesIllustration } from '../../../resources/activities/ilustrations/principles.svg';
import { ReactComponent as CostSavingIllustration } from '../../../resources/activities/ilustrations/cost-saving-house.svg';
import { ReactComponent as ScrumBoardIllustration } from '../../../resources/activities/ilustrations/scrum-board.svg';

const PassiveHouses = props => {
  const { t } = useTranslation();

  return (
    <div ref={props.activityRef}>
      <Reveal>
        <CategoryLabel laptopLeft>
          {t('pages.activities.labels.specialServices')}
        </CategoryLabel>
        <TitleUnderlined center laptopLeft>
          {t('pages.activities.passiveHouses.title')}
        </TitleUnderlined>
      </Reveal>
      <Reveal>
        <TwoColContainer main>
          <TwoCol className="main-section">
            <p className="mb">{t('pages.activities.passiveHouses.mainText')}</p>
            <h2>{t('pages.activities.passiveHouses.standards.title')}</h2>
            <ul>
              {t('pages.activities.passiveHouses.standards.list', {
                returnObjects: true
              }).map((item, id) => (
                <li key={id}>{item}</li>
              ))}
            </ul>
          </TwoCol>
          <TwoCol className="mobile-none main-section-image">
            <img src={passiveHouseImage} alt="passive-houses" />
          </TwoCol>
        </TwoColContainer>
      </Reveal>
      <ThreeColContainer>
        <Reveal delay={revealTimeoutNext(0)}>
          <ThreeCol>
            <PrinciplesIllustration className="illustration passive-houses-illustration" />
            <h2>{t('pages.activities.passiveHouses.principles.title')}</h2>
            <p>{t('pages.activities.passiveHouses.principles.text')}</p>
          </ThreeCol>
        </Reveal>
        <Reveal delay={revealTimeoutNext(1)}>
          <ThreeCol>
            <CostSavingIllustration className="illustration passive-houses-illustration" />
            <h2>{t('pages.activities.passiveHouses.costSaving.title')}</h2>
            <p>{t('pages.activities.passiveHouses.costSaving.text')}</p>
          </ThreeCol>
        </Reveal>
        <Reveal delay={revealTimeoutNext(2)}>
          <ThreeCol>
            <ScrumBoardIllustration className="illustration-not-custom" />
            <h2>{t('pages.activities.passiveHouses.knowledge.title')}</h2>
            <p>{t('pages.activities.passiveHouses.knowledge.text')}</p>
          </ThreeCol>
        </Reveal>
      </ThreeColContainer>
    </div>
  );
};

export default PassiveHouses;
