import styled from 'styled-components';
import { device } from '../../devices';
import theme from '../.../../../theme';

export default styled.div`
  width: 100%;
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom : '24px'};

  padding-top: ${props => (props.paddingTop ? props.paddingTop : '24px')};

  @media ${device.tablet} {
    padding-bottom: ${props =>
      props.paddingBottom ? props.paddingBottom : '40px'};

    padding-top: ${props => (props.paddingTop ? props.paddingTop : '80px')};
  }

  @media ${device.laptop} {
    padding-bottom: ${props =>
      props.paddingBottom ? props.paddingBottom : '80px'};
  }

  & .inner-container {
    width: 100%;
    height: 100%;
    padding-right: ${props => theme.sectionPadding.mobile}px;
    padding-left: ${props => theme.sectionPadding.mobile}px;
    margin-right: auto;
    margin-left: auto;
    max-width: ${theme.contentMaxWidth}px;

    @media ${device.tablet} {
      padding-right: ${props => theme.sectionPadding.tablet}px;
      padding-left: ${props => theme.sectionPadding.tablet}px;

      ${props =>
        props.fullWidth && {
          paddingRight: '0',
          paddingLeft: '0',
          maxWidth: '100%'
        }}
    }

    ${props =>
      props.fullWidth && {
        paddingRight: '0',
        paddingLeft: '0',
        maxWidth: '100%'
      }}
  }

  & .mt-md {
    @media ${device.tablet} {
      margin-top: 20px;
    }
  }
`;
