import styled from 'styled-components';
import theme from '../../theme';
import { device } from '../../devices';
import { FlexRow } from '../ui/globals';

export const Container = styled(FlexRow)`
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  /* background-color: ${props => theme.color.grey}; */
  margin-bottom: 32px;
  > img {
    width: 400px;
    height: 400px;
    object-fit: cover;

    @media ${device.laptop} {
      width: 600px;
      height: 600px;
    }
  }

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`;

export default styled.div`
  width: 100%;
  height: 280px;
  cursor: default;
  padding: 16px;
  line-height: 32px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
  > h2 {
    color: ${theme.color.secondary};
  }

  > .email {
    margin-bottom: 12px;
  }

  > .phone {
    margin-bottom: 12px;
  }

  @media ${device.mobileM} {
    width: 280px;
  }

  @media ${device.tablet} {
    width: 300px;
    margin-right: -80px;

    ${props =>
      props.reverse && {
        marginRight: '0',
        marginLeft: '-80px'
      }}
  }

  @media ${device.laptop} {
    width: 400px;
    height: 400px;

    > .email {
      margin-bottom: 24px;
    }

    > .phone {
      margin-bottom: 24px;
    }

    > h2 {
      margin-bottom: 32px;
    }
  }
`;
