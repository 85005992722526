import React from 'react';
import {
  PageLayout,
  Section,
  TitleUnderlined,
  MainText,
  Projects,
  Reveal,
  HeadInfo
} from '../../../components';
import bgImage from '../../../resources/services/riviera.JPG';
import { getProjectsByService } from '../../../utils';
import { projects } from '../../../data';
import { useTranslation } from 'react-i18next';

const Civil = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeadInfo pathId="civil" />
      <PageLayout
        image={bgImage}
        title={t('pages.civil.top.title')}
        description={t('pages.civil.top.desc')}
      >
        <Reveal>
          <Section>
            <TitleUnderlined disableTitle lineShowAlways />
            <MainText>{t('pages.civil.mainText')}</MainText>
          </Section>
          <TitleUnderlined>{t('components.projects.title')}</TitleUnderlined>
        </Reveal>
        <Reveal fraction={0}>
          <Projects projects={getProjectsByService(projects, 'civil')} />
        </Reveal>
      </PageLayout>
    </>
  );
};

export default Civil;
