import React, { useEffect, useState } from 'react';
import Card, { Container } from './styles';
import { useWindowSize } from '../../hooks';
import { tabletWidth } from '../../devices';

const OfficeCard = props => {
  const { data, reverse } = props;
  const { city, phone, email, address, postcode, officeImage } = data;
  const [showImage, setShowImage] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width < tabletWidth) {
      setShowImage(false);
    } else {
      setShowImage(true);
    }
  }, [width]);

  return (
    <Container reverse={reverse}>
      <Card className={'base-card active'} reverse={reverse}>
        <h2>{city}</h2>
        <p className="phone">{phone}</p>
        <a href={`mailto:${email}`} className="email">
          {email}
        </a>
        <p className="address">{`${address}`}</p>
        <p className="address">
          {postcode}&nbsp;&nbsp;{city}
        </p>
      </Card>
      {showImage ? <img src={officeImage} alt="city" /> : null}
    </Container>
  );
};

export default OfficeCard;
