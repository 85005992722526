import styled from 'styled-components';
import { device } from '../../../devices';

export default styled.div`
  display: flex;
  flex: 1 0 33%;
  position: relative;
  text-align: left;
  width: 100%;
  min-width: 150px;
  max-width: 350px;
  border-radius: 0px;
  margin-bottom: 24px;
  line-height: 32px;
  cursor: default;
  background-color: ${props =>
    props.bgColor ? props.bgColor : props.theme.color.grey};

  @media ${device.tablet} {
    margin-left: 8px;
    margin-right: 8px;
    min-width: 150px;
  }

  @media ${device.laptop} {
    width: 300px;
  }

  @media ${device.laptopL} {
    width: 350px;
  }

  & .card-body {
    flex: 1 0 auto;
    width: 100%;
    display: flex;
    min-height: 1px;
    padding: 1.25rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    & span.line {
      width: 100%;
      margin-top: 16px;
    }

    & .enum {
      margin-top: 40px;
      margin-left: 16px;
    }
  }
`;
