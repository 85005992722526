import styled from 'styled-components';
import theme from '../../theme';
import { device } from '../../devices';
import { FlexCol } from '../../components';

export const Container = styled(FlexCol)``;

export const ProjectContent = styled(FlexCol)`
  @media ${device.laptop} {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }

  > .project-content-description {
    flex-grow: ${props => (props.withoutTable ? 1 : 0)};
    @media ${device.laptop} {
      margin-left: 20px;
    }
  }

  > .project-content-table {
    margin-top: ${theme.sectionPadding.mobile}px;
    text-align: center;
    width: 100%;

    @media ${device.laptop} {
      margin-right: 20px;
      margin-top: 0;
      flex-basis: 50%;
    }

    & h3 {
      color: ${theme.color.primary};
      text-transform: uppercase;
    }
  }

  & .slash {
    margin-left: 12px;
    margin-right: 12px;
  }
`;

export const Gallery = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: center;
  max-width: 928px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  width: 100%;

  > a.photo-wrapper {
    cursor: pointer;
    overflow: hidden;
    display: flex;
    flex: 0 1 49%;
    margin: 0.5%;
    height: ${props => props.photoWidth}px;

    @media ${device.tablet} {
      flex-basis: 30%;
    }

    @media ${device.laptop} {
      flex-basis: 24%;
    }

    > img {
      height: auto;
      width: 100%;
      object-fit: cover;
      transition: all 0.5s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  /* > a.photo-wrapper:last-child {
    margin-right: auto;
  } */
`;

export const Sources = styled.div`
  width: 100%;
  font-size: 10px;
  color: #b4b8bc;
  word-break: break-word;
  text-align: center;
`;
