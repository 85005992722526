import React from 'react';
import Card from './styles';
import { ButtonMore } from '../../components';

const ServiceCard = props => {
  const { image, title, text, onClick } = props;
  return (
    <Card className="base-card">
      <img src={image} alt={`img_${title}`} />
      <div className="body">
        <h3>{title}</h3>
        <p>{text}</p>
        <ButtonMore onClick={onClick} />
      </div>
    </Card>
  );
};

export default ServiceCard;
