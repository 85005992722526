import styled from 'styled-components';
import { device } from '../../devices';
import theme from '../../theme';
import { FlexCol, FlexRow } from '../../components';

export const TwoCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;

  @media ${device.laptop} {
    width: 50%;
  }

  &.main-section {
    align-self: flex-start;
    display: flex;
    @media ${device.laptop} {
      flex-basis: 40%;
      min-height: 480px;
      > p {
        text-align: left;
      }
    }
    > p {
      font-family: 'Poppins', sans-serif;
      font-size: 1.5rem;
      line-height: 2rem;

      &.mb-lg {
        margin-bottom: 60px;
      }
    }
  }

  &.main-section-image {
    @media ${device.laptop} {
      flex-basis: 60%;
      align-self: stretch;
    }

    > img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &.image-smaller {
    max-height: 450px;
  }

  & .mb {
    margin-bottom: 24px;
  }

  & ul {
    margin-left: 24px;
    text-align: left;

    > li:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const TwoColContainer = styled(FlexCol)`
  text-align: center;
  margin-bottom: 32px;

  @media ${device.laptop} {
    ${props =>
      props.main && {
        marginBottom: '80px'
      }}
  }

  @media ${device.tablet} {
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
    text-align: justify;

    & h2 {
      text-align: left;
    }
  }

  & ${TwoCol}:nth-child(2) {
    margin-top: ${props => (props.reverse ? '0' : '16px')};
    &.main-section-image,
    &.mt-0 {
      margin-top: 0;
    }

    > svg {
      margin-right: auto;
    }
  }

  & ${TwoCol}:nth-child(1) {
    margin-top: ${props => (props.reverse ? '16px' : '0')};
    &.main-section-image,
    &.mt-0 {
      margin-top: 0;
    }

    > svg {
      margin-left: auto;
    }
  }

  .illustration {
    width: 150px;
    height: auto;

    @media ${device.tablet} {
      width: 100%;
      max-width: 250px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .illustration-not-custom {
    width: 150px;
    height: auto;

    @media ${device.tablet} {
      width: 100%;
    }
  }

  .illustration-lg {
    @media ${device.tablet} {
      height: 300px;
    }
  }

  margin-top: ${props => (props.subsection ? '24px' : 0)};

  .mobile-none {
    display: none;

    @media ${device.laptop} {
      display: flex;
      padding-left: 48px;
    }
  }
`;

export const OneColContainer = styled(FlexCol)`
  text-align: left;
  align-items: center;
`;

export const ThreeColContainer = styled(TwoColContainer)`
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  @media ${device.tablet} {
    > div {
      flex: 1 1 100%;
      max-width: 50%;
    }
    > div:first-child {
      padding-right: 16px;
    }

    > div:nth-child(2) {
      padding-left: 16px;
    }
  }

  @media ${device.laptop} {
    > div {
      max-width: 33%;
    }

    > div:first-child {
      padding-left: 16px;
    }
    > div:nth-child(2) {
      padding-right: 16px;
    }
    > div:nth-child(3) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;

export const ThreeCol = styled.div`
  margin-top: 32px;

  & > p {
    align-items: flex-start;
  }

  @media ${device.tablet} {
    & > .illustration,
    .illustration-not-custom {
      height: 100px;
      width: auto;
    }
  }
`;

export const NavDesktop = styled(FlexRow)`
  display: none;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 600;
  color: ${theme.color.primary};
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  justify-content: space-evenly;
  align-items: flex-end;
  max-width: 900px;
  @media ${device.tablet} {
    display: flex;
  }

  > .nav-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    &:hover {
      color: ${theme.color.secondary};
      cursor: pointer;
    }
    > svg {
      width: 50px;
      height: auto;
      margin: 0 auto;
    }
  }
`;

export const NavMobile = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 40px;

  ul > li:not(:first-child) {
    margin-top: 24px;
  }

  ul > li {
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 600;
    color: #123f73;
    position: relative;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 34px;
    display: block;
    width: 100%;
  }
`;

export const ActivityNavigation = styled.div`
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;

  & button {
    @media ${device.tablet} {
      display: none;
    }
  }
`;
