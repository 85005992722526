import styled from 'styled-components';
import theme from '../../theme';

export const CancelButton = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > span {
    position: relative;
    display: block;
    width: 25px;
    height: 2px;
    background-color: ${theme.color.primary};
  }

  span:nth-child(2) {
    transform: rotate(45deg);
    top: -9px;
  }

  span:nth-child(1) {
    transform: rotate(-45deg);
    top: 9px;
  }
`;

export const Container = styled.div`
  background-color: ${theme.color.white};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 14;
`;
