import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitleUnderlined, CategoryLabel, Reveal } from '../../../components';
import {
  TwoColContainer,
  TwoCol,
  ThreeColContainer,
  ThreeCol
} from '../styles';
import { revealTimeoutNext } from '../../../utils';
import powerPlantImage from '../../../resources/activities/power-plant.jpg';

const PowerPlant = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Reveal>
        <CategoryLabel laptopLeft>
          {t('pages.activities.labels.specialServices')}
        </CategoryLabel>
        <TitleUnderlined center laptopLeft>
          {t('pages.activities.powerPlant.title')}
        </TitleUnderlined>
      </Reveal>
      <Reveal>
        <TwoColContainer main>
          <TwoCol className="main-section">
            <p className="mb-lg">{t('pages.activities.powerPlant.mainText')}</p>
            <h2>{t('pages.activities.powerPlant.methods.title')}</h2>
            <ul>
              {t('pages.activities.powerPlant.methods.list', {
                returnObjects: true
              }).map((item, id) => (
                <li key={id}>{item}</li>
              ))}
            </ul>
          </TwoCol>
          <TwoCol className="mobile-none main-section-image">
            <img src={powerPlantImage} alt="power-plant" />
          </TwoCol>
        </TwoColContainer>
      </Reveal>
      <ThreeColContainer>
        <Reveal delay={revealTimeoutNext(0)}>
          <ThreeCol>
            <h2>{t('pages.activities.powerPlant.resistance.title')}</h2>
            <p>{t('pages.activities.powerPlant.resistance.text')}</p>
          </ThreeCol>
        </Reveal>
        <Reveal delay={revealTimeoutNext(1)}>
          <ThreeCol>
            <h2>{t('pages.activities.powerPlant.category1.title')}</h2>
            <p>{t('pages.activities.powerPlant.category1.text')}</p>
          </ThreeCol>
        </Reveal>
        <Reveal delay={revealTimeoutNext(2)}>
          <ThreeCol>
            <h2>{t('pages.activities.powerPlant.category2.title')}</h2>
            <p>{t('pages.activities.powerPlant.category2.text')}</p>
          </ThreeCol>
        </Reveal>
      </ThreeColContainer>
    </div>
  );
};

export default PowerPlant;
