import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitleUnderlined, CategoryLabel, Reveal } from '../../../components';
import { TwoColContainer, TwoCol } from '../styles';
import antiFireImage from '../../../resources/activities/antifire.jpg';
import { ReactComponent as QuestionIllustration } from '../../../resources/activities/ilustrations/question.svg';

const AntiFire = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Reveal>
        <CategoryLabel laptopLeft>
          {t('pages.activities.labels.specialServices')}
        </CategoryLabel>
        <TitleUnderlined center laptopLeft>
          {t('pages.activities.antiFire.title')}
        </TitleUnderlined>
      </Reveal>
      <Reveal>
        <TwoColContainer main>
          <TwoCol className="main-section">
            <p className="mb">{t('pages.activities.antiFire.mainText')}</p>
            <h2>{t('pages.activities.antiFire.solutions.title')}</h2>
            <ul>
              {t('pages.activities.antiFire.solutions.list', {
                returnObjects: true
              }).map((item, id) => (
                <li key={id}>{item}</li>
              ))}
            </ul>
          </TwoCol>
          <TwoCol className="mobile-none main-section-image">
            <img src={antiFireImage} alt="steel-constructions" />
          </TwoCol>
        </TwoColContainer>
      </Reveal>
      <Reveal>
        <TwoColContainer>
          <TwoCol>
            <QuestionIllustration className="illustration-not-custom illustration-lg" />
          </TwoCol>
          <TwoCol>
            <h2>{t('pages.activities.antiFire.whatIsIncluded.title')}</h2>
            <p className="mb">
              {t('pages.activities.antiFire.whatIsIncluded.subtitle')}
            </p>
            <ul>
              {t('pages.activities.antiFire.whatIsIncluded.list', {
                returnObjects: true
              }).map((item, id) => (
                <li key={id}>{item}</li>
              ))}
            </ul>
          </TwoCol>
        </TwoColContainer>
      </Reveal>
    </div>
  );
};

export default AntiFire;
