import React from 'react';
import { Container, CancelButton } from './styles';

const Modal = props => {
  const { open, onClose, children } = props;

  if (!open) return null;

  return (
    <Container>
      <CancelButton onClick={onClose}>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
      </CancelButton>
      {children}
    </Container>
  );
};

export default Modal;
