import React from 'react';
import styled from 'styled-components';
import ProjectCard from './project-card/ProjectCard';
import { FlexCol } from '../ui/globals';
import { device } from '../../devices';
import theme from '../../theme';

const Container = styled(FlexCol)`
  margin-bottom: ${theme.sectionPadding.laptop}px;
  > a:not(:first-child) {
    margin-top: 16px;
  }

  @media ${device.tablet} {
    flex-direction: row;
    flex-wrap: wrap;

    > a {
      margin-top: 16px;
      margin-left: 16px;
    }
  }
`;
const Projects = props => {
  const { projects } = props;
  return (
    <Container>
      {projects.map(project => (
        <ProjectCard key={project.name} project={project} />
      ))}
    </Container>
  );
};

export default Projects;
