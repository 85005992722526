import React, { useState, useEffect } from 'react';
import { Container } from './styles';
import { Line } from '../../components';

const PageLayout = props => {
  const {
    children,
    image,
    imagePosition,
    imageFlip,
    imageHeight,
    title,
    description,
    bgImageClassName
  } = props;

  const [noContent, setNoContent] = useState(title ? false : true);

  useEffect(() => {
    if (title) setNoContent(false);
  }, [title]);

  return (
    <Container
      image={image}
      imagePosition={imagePosition}
      imageFlip={imageFlip}
      imageHeight={imageHeight}
    >
      <div className="top-container">
        <div className="background-image-container">
          <div
            className={`background-image${
              bgImageClassName ? ' ' + bgImageClassName : ''
            }`}
          />
        </div>
        {noContent ? null : (
          <div className="top-content">
            <span className="top-title">{title}</span>
            <Line showAlways />
            <p className="top-description">{description}</p>
          </div>
        )}
      </div>
      <div className="content">{children}</div>
    </Container>
  );
};

export default PageLayout;
