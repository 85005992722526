import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  EnumCards,
  TitleUnderlined,
  MainText,
  Reveal
} from '../../../components';

const Career = props => {
  const { t } = useTranslation();
  return (
    <div ref={props.scrollToRef}>
      <Reveal>
        <TitleUnderlined>{t('pages.aboutUs.career.title')}</TitleUnderlined>
        <MainText>{t('pages.aboutUs.career.mainText')}</MainText>
      </Reveal>
      <EnumCards
        data={t('pages.aboutUs.career.info', { returnObjects: true })}
      />
    </div>
  );
};

export default Career;
