import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FlexCol } from '../../components';
import { ReactComponent as NotFoundIcon } from '../../resources/icons/not_found.svg';
import { device } from '../../devices';

const NotFound = styled(NotFoundIcon)`
  width: 150px;
  height: 150px;
  margin-top: 80px;

  @media ${device.tablet} {
    width: 350px;
    height: 350px;
    margin-top: 160px;
  }
`;

const H1 = styled.h1`
  margin-bottom: 40px;
  position: relative;
  top: -10px;

  @media ${device.tablet} {
    margin-bottom: 80px;
  }
`;

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <FlexCol>
      <NotFound />
      <H1>404: {t('pages.notFound.title')}</H1>
    </FlexCol>
  );
};

export default PageNotFound;
