import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetLinks } from '../../../hooks';
import { NavLink } from 'react-router-dom';
import { Container, Content } from './styles';
import { ReactComponent as FacebookIcon } from '../../../resources/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../../resources/icons/twitter.svg';
import { ReactComponent as LinkedinIcon } from '../../../resources/icons/linkedin.svg';

const Footer = props => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();
  const links = useGetLinks();

  return (
    <Container>
      <Content>
        <div className="footer-item">
          <h3 className="title">De Bondt</h3>
          <ul className="list">
            <li>
              <NavLink to={links.about} activeClassName="active-link">
                {t('components.navigation.about')}
              </NavLink>
            </li>
            <li>
              <NavLink to={links.industrial} activeClassName="active-link">
                {t('services.industrial')}
              </NavLink>
            </li>
            <li>
              <NavLink to={links.civil} activeClassName="active-link">
                {t('services.civil')}
              </NavLink>
            </li>
            <li>
              <NavLink to={links.infrastructure} activeClassName="active-link">
                {t('services.infrastructure')}
              </NavLink>
            </li>
            <li>
              <NavLink to={links.activities} activeClassName="active-link">
                {t('components.navigation.activities')}
              </NavLink>
            </li>
            <li>
              <NavLink to={links.references} activeClassName="active-link">
                {t('components.navigation.references')}
              </NavLink>
            </li>
            <li>
              <NavLink to={links.contact} activeClassName="active-link">
                {t('components.navigation.contact')}
              </NavLink>
            </li>
            <li>
              <NavLink to={links.career} activeClassName="active-link">
                {t('components.navigation.career')}
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="footer-item">
          <h3 className="title"> {t('components.navigation.contact')}</h3>
          <ul className="list">
            <li>Rybárska 7389</li>
            <li className="address-margin">911 01 Trenčín</li>
            <li>
              <a href="tel:+421 (32) 748 00 11">+421 (32) 748 00 11</a>
            </li>
            <li>
              <a href="mailto:info@debondt.sk">info@debondt.sk</a>
            </li>
          </ul>
        </div>
        <div className="footer-item">
          <h3 className="title">{t('components.navigation.invoiceDetails')}</h3>
          <ul className="list">
            <li>IČO: 34151851</li>
            <li>DIČ: 2020173903</li>
            <li>IČ DPH: SK2020173903</li>
            <li>OR OS Trenčín odd. Sro. vl. č. 1380/R</li>
          </ul>
        </div>
        <div className="footer-item social">
          <h3 className="title"> {t('components.navigation.followUs')}</h3>
          <ul>
            <li>
              <a href="https://www.facebook.com/de.bondt.sro/">
                <FacebookIcon className="social-icon" />
              </a>
            </li>
            <li>
              <a href="https://https://www.linkedin.com/de.bondt.sro/">
                <LinkedinIcon className="social-icon" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/">
                <TwitterIcon className="social-icon" />
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-item copyright">
          &copy;{currentYear} De Bondt, s.r.o.
        </div>
      </Content>
    </Container>
  );
};
export default Footer;
