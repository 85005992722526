import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TitleUnderlined, CategoryLabel, Reveal } from '../../../components';
import { TwoColContainer, TwoCol } from '../styles';
import steelImage from '../../../resources/activities/steel.jpg';
import { ReactComponent as ControlIllustration } from '../../../resources/activities/ilustrations/control.svg';
import { ReactComponent as OptimizationIllustration } from '../../../resources/activities/ilustrations/optimization.svg';

const SteelConstructions = props => {
  const [showControlText, setShowControlText] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const currentLanguage = i18n.language;
    if (currentLanguage !== 'sk') {
      setShowControlText(false);
    } else {
      setShowControlText(true);
    }
  }, [i18n.language]);

  return (
    <div ref={props.activityRef}>
      <Reveal>
        <CategoryLabel laptopLeft>
          {t('pages.activities.labels.steelConstructions')}
        </CategoryLabel>
        <TitleUnderlined center laptopLeft>
          {t('pages.activities.steelConstructions.title')}
        </TitleUnderlined>
      </Reveal>
      <Reveal>
        <TwoColContainer>
          <TwoCol className="main-section">
            <p>{t('pages.activities.steelConstructions.mainText')}</p>
          </TwoCol>
          <TwoCol className="mobile-none main-section-image">
            <img src={steelImage} alt="steel-constructions" />
          </TwoCol>
        </TwoColContainer>
      </Reveal>
      {showControlText ? (
        <Reveal>
          <TwoColContainer>
            <TwoCol>
              <ControlIllustration className="illustration" />
            </TwoCol>
            <TwoCol>
              <h2>{t('pages.activities.steelConstructions.control.title')}</h2>
              <p>{t('pages.activities.steelConstructions.control.text')}</p>
            </TwoCol>
          </TwoColContainer>
        </Reveal>
      ) : null}
      <Reveal>
        <TwoColContainer reverse={showControlText}>
          <TwoCol>
            <OptimizationIllustration className="illustration" />
          </TwoCol>
          <TwoCol>
            <h2>
              {t('pages.activities.steelConstructions.optimization.title')}
            </h2>
            <p>{t('pages.activities.steelConstructions.optimization.text')}</p>
          </TwoCol>
        </TwoColContainer>
        <TwoColContainer reverse={showControlText}>
          <TwoCol>
            <h2>{t('pages.activities.steelConstructions.software.title')}</h2>
            <p>{t('pages.activities.steelConstructions.software.text')}</p>
          </TwoCol>
          <TwoCol></TwoCol>
        </TwoColContainer>
      </Reveal>
    </div>
  );
};

export default SteelConstructions;
