import React from 'react';
import styled from 'styled-components';
import { Map } from '../../../components';
import { device } from '../../../devices';

const ContainerElement = styled.div`
  position: relative;
  height: 400px;

  @media ${device.laptop} {
    height: 500px;
  }
`;

const MapContainer = props => {
  const { markers } = props;
  return (
    <Map
      isMarkerShown
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAwLa6McAFe4E6LJPIpJmc_qJaKT0IWffM&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<ContainerElement />}
      mapElement={<div style={{ height: `100%` }} />}
      markers={markers}
    />
  );
};

export default MapContainer;
