import { useHistory } from 'react-router-dom';
import paths from '../../paths';
import { useTranslation } from 'react-i18next';

const getUrl = (pathId, param, language) => {
  if (!paths.hasOwnProperty(pathId)) {
    return;
  }

  if (!language) {
    return;
  }

  let url = paths[pathId][language];
  if (!param) {
    return url;
  }

  const urlParts = url.split('/');
  url = urlParts
    .map(part => {
      if (part.includes(':')) {
        const urlParam = part.replace(':', '');
        if (param.hasOwnProperty(urlParam)) {
          return param[urlParam];
        }
      }
      return part;
    })
    .join('/');

  return url;
};

export default function useRedirectTo() {
  const { i18n } = useTranslation();
  const history = useHistory();

  return (pathId, param = null) => {
    history.push(getUrl(pathId, param, i18n.language));
  };
}

export function useHref(pathId, param = null) {
  const { i18n } = useTranslation();
  return getUrl(pathId, param, i18n.language);
}

export function useGetLinks() {
  const { i18n } = useTranslation();
  return Object.keys(paths).reduce((acc, pathId) => {
    acc[pathId] = paths[pathId][i18n.language];
    return acc;
  }, {});
}
