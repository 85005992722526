import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DropdownMenu } from '../../../index';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink } from 'react-router-dom';
import { device } from '../../../../devices';
import { services } from '../../../../data';
import { ReactComponent as ChevronDown } from '../../../../resources/icons/chevron_down.svg';

const Container = styled.div`
  display: none;
  text-transform: lowercase;

  @media ${device.laptop} {
    display: flex;
  }

  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;

  & .nav-desktop-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  & .nav-desktop-links {
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
    > li {
      display: inline-flex;
      margin-left: 24px;
      cursor: default;
      cursor: pointer;
      padding-left: 8px;
      padding-right: 8px;
    }

    & > li:first-child {
      margin-left: 0;
    }
  }

  & .menu-item-service {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const StyledChevronDown = styled(ChevronDown)`
  transition: transform 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: ${props => (props.flip ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DesktopMenu = props => {
  const { locale, changeLocale, languages, links } = props;
  const { t } = useTranslation();
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [localeDropdownOpen, setLocaleDropdownOpen] = useState(false);
  const prevServiceDropdownOpen = useRef(serviceDropdownOpen);
  const prevLocaleDropdownOpen = useRef(localeDropdownOpen);
  const serviceRef = useRef(null);
  const localeRef = useRef(null);
  const toggleService = () => setServiceDropdownOpen(prevValue => !prevValue);
  const toggleLocale = () => setLocaleDropdownOpen(prevValue => !prevValue);

  const handleLocaleClick = event => {
    changeLocale(event.target.dataset.lng);
    toggleLocale();
  };

  return (
    <Container className="nav-desktop">
      <div className="nav-desktop-content">
        <ul className="nav-desktop-links">
          <li>
            <NavLink to={links.about} activeClassName="active-link">
              {t('components.navigation.about')}
            </NavLink>
          </li>
          <li className="dd-anchor" ref={serviceRef} onClick={toggleService}>
            {t('components.navigation.services')}
          </li>
          <DropdownMenu
            isOpen={serviceDropdownOpen}
            prevOpen={prevServiceDropdownOpen}
            setOpen={setServiceDropdownOpen}
            menuItems={services}
            anchorRef={serviceRef}
          >
            {Object.values(services).map(service => (
              <MenuItem key={service} disableGutters>
                <NavLink
                  to={links[service]}
                  onClick={toggleService}
                  className="menu-item-service"
                >
                  {t(`services.${service}`)}
                </NavLink>
              </MenuItem>
            ))}
          </DropdownMenu>
          <li>
            <NavLink to={links.activities} activeClassName="active-link">
              {t('components.navigation.activities')}
            </NavLink>
          </li>
          <li>
            <NavLink to={links.references} activeClassName="active-link">
              {t('components.navigation.references')}
            </NavLink>
          </li>
          <li>
            <NavLink to={links.contact} activeClassName="active-link">
              {t('components.navigation.contact')}
            </NavLink>
          </li>
          <li>|</li>
          <li
            onClick={toggleLocale}
            ref={localeRef}
            className="nav-desktop-locale"
          >
            {locale}
            <StyledChevronDown flip={localeDropdownOpen ? 1 : 0} />
          </li>
          <DropdownMenu
            isOpen={localeDropdownOpen}
            prevOpen={prevLocaleDropdownOpen}
            setOpen={setLocaleDropdownOpen}
            anchorRef={localeRef}
          >
            {languages.map(lng => (
              <MenuItem
                data-lng={lng}
                onClick={e => handleLocaleClick(e)}
                key={lng}
              >
                {lng}
              </MenuItem>
            ))}
          </DropdownMenu>
        </ul>
      </div>
    </Container>
  );
};

export default DesktopMenu;
