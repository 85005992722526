import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollToRef } from '../../utils';
import {
  Section,
  PageLayout,
  TitleUnderlined,
  MainText,
  Reveal,
  HeadInfo
} from '../../components';
import Company from './company/Company';
import Team from './team/Team';
import bgImage from '../../resources/about-us/team.JPG';
import Career from './career/Career';

const AboutUs = props => {
  const { isCareer } = props;
  const { t } = useTranslation();
  const careerRef = useRef(null);

  useEffect(() => {
    if (isCareer) scrollToRef(careerRef);
  }, [isCareer]);

  return (
    <>
      <HeadInfo pathId="about" />
      <PageLayout
        image={bgImage}
        title={t('pages.aboutUs.top.title')}
        description={t('pages.aboutUs.top.desc')}
        bgImageClassName="about-us"
      >
        <Section>
          <Reveal>
            <TitleUnderlined>{t('pages.aboutUs.why.title')}</TitleUnderlined>
            <MainText>{t('pages.aboutUs.why.mainText')}</MainText>
          </Reveal>
        </Section>
        <Section>
          <Company />
        </Section>
        <Section>
          <Team />
        </Section>
        <Section>
          <Career scrollToRef={careerRef} />
        </Section>
      </PageLayout>
    </>
  );
};

export default AboutUs;
