import React, { useState } from 'react';
import { Button, Modal } from '../../../../components';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ReactComponent as FilterIcon } from '../../../../resources/icons/filter.svg';
import Checkbox from '../Checkbox';
import { Container, StyledFormLabel, ModalContent } from './styles';

const MobileFilter = props => {
  const {
    locations,
    onLocationChange,
    services,
    onServiceChange,
    locationState,
    serviceState,
    translation
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Container>
      <Button
        center
        onClick={() => setIsModalOpen(prevIsOpen => !prevIsOpen)}
        icon={<FilterIcon />}
      >
        {translation.filter}
      </Button>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(prevIsOpen => !prevIsOpen)}
      >
        <ModalContent>
          <div className="modal-header">
            <h2 className="modal-title">{translation.title}</h2>
          </div>
          <div className="modal-body">
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">
                {translation.filterByState}
              </StyledFormLabel>
              <FormGroup>
                {Object.keys(locations).map(key => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        checked={locationState[key]}
                        onChange={onLocationChange}
                        name={key}
                      />
                    }
                    label={
                      <span style={{ fontFamily: 'Poppins, serif' }}>
                        {locations[key]}
                      </span>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset">
              <StyledFormLabel component="legend">
                {translation.filterByService}
              </StyledFormLabel>
              <FormGroup>
                {Object.keys(services).map(key => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        checked={serviceState[key]}
                        onChange={onServiceChange}
                        name={key}
                      />
                    }
                    label={
                      <span style={{ fontFamily: 'Poppins, serif' }}>
                        {services[key]}
                      </span>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default MobileFilter;
