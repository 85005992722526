import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitleUnderlined, CategoryLabel, Reveal } from '../../../components';
import { TwoColContainer, TwoCol } from '../styles';
import counselingImage from '../../../resources/activities/counseling.jpg';
import { ReactComponent as ModelingIllustration } from '../../../resources/activities/ilustrations/modeling.svg';

const Counseling = props => {
  const { t } = useTranslation();

  return (
    <div ref={props.activityRef}>
      <Reveal>
        <CategoryLabel laptopLeft>
          {t('pages.activities.labels.counseling')}
        </CategoryLabel>
        <TitleUnderlined center laptopLeft>
          {t('pages.activities.counseling.title')}
        </TitleUnderlined>
      </Reveal>
      <Reveal>
        <TwoColContainer main>
          <TwoCol className="main-section">
            <p className="mb">{t('pages.activities.counseling.mainText')}</p>
            <h2>{t('pages.activities.counseling.services.title')}</h2>
            <ul>
              {t('pages.activities.counseling.services.list', {
                returnObjects: true
              }).map((item, id) => (
                <li key={id}>{item}</li>
              ))}
            </ul>
          </TwoCol>
          <TwoCol className="mobile-none main-section-image">
            <img src={counselingImage} alt="counseling" />
          </TwoCol>
        </TwoColContainer>
      </Reveal>
      <Reveal>
        <TwoColContainer>
          <TwoCol>
            <ModelingIllustration className="illustration-not-custom illustration-lg" />
          </TwoCol>
          <TwoCol>
            <h2>{t('pages.activities.counseling.documentation.title')}</h2>
            <p className="mb">
              {t('pages.activities.counseling.documentation.text')}
            </p>
          </TwoCol>
        </TwoColContainer>
      </Reveal>
    </div>
  );
};

export default Counseling;
