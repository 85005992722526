import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import Routes from './routes';
import { BackToTop, CareerModal } from './components';
import './i18n';

function App(props) {
  return (
    <React.StrictMode>
      <div className="App">
        <BackToTop />
        <Suspense fallback={null}>
          <CareerModal />
          <SimpleReactLightbox>
            <BrowserRouter>
              <Routes {...props} />
            </BrowserRouter>
          </SimpleReactLightbox>
        </Suspense>
      </div>
    </React.StrictMode>
  );
}

export default App;
