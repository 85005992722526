import styled from 'styled-components';
import theme from '../../theme';
import { device } from '../../devices';

export default styled.div`
  max-width: calc(50% - 16px);
  margin-left: 16px;
  margin-bottom: 16px;
  width: 150px;
  height: 150px;
  color: ${theme.color.primary};
  transition: all ${theme.transitionTime};
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  fill: ${theme.color.primary};
  border: 1px solid rgba(0, 0, 0, 0.12);

  @media ${device.tablet} {
    max-width: 200px;
    flex: 0 0 33%;
    width: 200px;
    height: 200px;
  }

  & svg {
    width: 60px;
    height: auto;
    margin-bottom: 8px;
  }

  & .title {
  }

  &:hover {
    background-color: ${theme.color.primary};
    color: ${theme.color.white};
    fill: ${theme.color.white};
  }
`;
