import React, { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SRLWrapper } from 'simple-react-lightbox';
import { tabletWidth } from '../../devices';
import theme from '../../theme';
import { useWindowSize } from '../../hooks';
import { importFirst } from '../../utils';
import {
  TitleUnderlined,
  MainText,
  PageLayout,
  Section,
  CategoryLabel,
  ContactPerson
} from '../../components';
import Table from './table/Table';
import { team, projects } from '../../data';
import { Container, ProjectContent, Gallery, Sources } from './styles';

const getProjectById = projectId => {
  return projects.find(project => project.name === projectId);
};

const getResponsiblePerson = personId => {
  return personId ? team[personId] : team.rmm;
};

const options = {
  settings: {
    overlayColor: 'rgba(0, 0, 0, .9)'
  }
};

const Project = props => {
  const { width } = useWindowSize();
  const { projectId } = useParams();
  const { t } = useTranslation();
  const [inlineCategories, setInlineCategories] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const photoRef = useRef(null);
  const [photoWidth, setPhotoWidth] = useState(133);
  const [isTablePresent, setIsTablePresent] = useState(true);
  const [tableContent, setTableContent] = useState([]);

  useEffect(() => {
    if (width < tabletWidth) {
      setInlineCategories(false);
    } else {
      setInlineCategories(true);
    }
  }, [width]);

  useEffect(() => {
    if (projectId) {
      setCurrentProject(getProjectById(projectId));
    }
  }, [projectId]);

  useEffect(() => {
    if (currentProject) {
      if (photoRef.current) setPhotoWidth(photoRef.current.clientWidth);

      const table = t(`${currentProject.translation}.table`, {
        returnObjects: true
      });
      if (Array.isArray(table)) {
        setIsTablePresent(true);
        setTableContent(table);
      } else {
        setIsTablePresent(false);
        setTableContent([]);
      }
    }
  }, [width, currentProject, t]);

  const formatCategories = () => {
    const activities = t(
      `pages.activities.labels.${currentProject.activities}`
    );
    const service = t(`services.${currentProject.service}`);
    const location = t(`locations.${currentProject.location}`);

    if (inlineCategories) {
      return (
        <>
          {service}
          <span className="slash">/</span>
          {location}
        </>
      );
    }
    return (
      <>
        {activities}
        <br />
        {service}
        <br />
        {location}
      </>
    );
  };

  if (currentProject === null) return null;

  return (
    <Container>
      <PageLayout
        image={importFirst(currentProject.bg)}
        imageHeight="80vh"
        imagePosition="top center"
      >
        <Section>
          <ProjectContent withoutTable={!isTablePresent}>
            <div className="project-content-description">
              <CategoryLabel mb>{formatCategories()}</CategoryLabel>
              <TitleUnderlined center>
                {t(`${currentProject.translation}.title`)}
              </TitleUnderlined>
              <MainText>
                {parse(t(`${currentProject.translation}.desc`))}
              </MainText>
            </div>
            {isTablePresent ? (
              <div className="project-content-table">
                <Table translation={tableContent} />
              </div>
            ) : null}
          </ProjectContent>
        </Section>
        {currentProject.galleryThumbnail &&
        currentProject.galleryThumbnail.length > 0 ? (
          <Section>
            <TitleUnderlined center>
              {t('pages.project.gallery')}
            </TitleUnderlined>
            {currentProject.sources && (
              <Sources>
                {t('pages.project.sources')}:{' '}
                <span>{currentProject.sources}</span>
              </Sources>
            )}
            <SRLWrapper options={options}>
              <Gallery photoWidth={photoWidth}>
                {currentProject.galleryThumbnail.map((img, id) => (
                  <a
                    className="photo-wrapper"
                    href={currentProject.gallery[id]}
                    data-attribute="SRL"
                    key={id}
                    ref={photoRef}
                  >
                    <img src={img} alt="" />
                  </a>
                ))}
              </Gallery>
            </SRLWrapper>
          </Section>
        ) : null}
        <Section bgColor={theme.color.grey}>
          <ContactPerson
            personData={getResponsiblePerson(currentProject.personInChargeId)}
            personId={currentProject.personInChargeId}
          />
        </Section>
      </PageLayout>
    </Container>
  );
};

export default Project;
