import React from 'react';
import { Line, FlexCol } from '../../components';
import styled from 'styled-components';
import { device } from '../../devices';

const TitleUnderlined = props => {
  const Container = styled(FlexCol)`
    & h1 {
      margin-bottom: 40px;
      color: ${props =>
        props.color ? props.color : props.theme.color.primary};
      text-align: ${props.center ? 'center' : 'inherit'};
    }

    & .line {
      margin-bottom: 40px;
    }

    @media ${device.laptop} {
      ${props =>
        props.laptopLeft && {
          alignItems: 'flex-start'
        }}
    }
  `;
  const {
    children,
    disableUnderline,
    disableTitle,
    color,
    center,
    laptopLeft,
    lineShowAlways
  } = props;
  return (
    <Container color={color} center={center} laptopLeft={laptopLeft}>
      {disableTitle ? null : <h1>{children}</h1>}
      {disableUnderline ? null : (
        <Line disabled={disableUnderline} showAlways={lineShowAlways} />
      )}
    </Container>
  );
};

export default TitleUnderlined;
