import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitleUnderlined, CategoryLabel, Reveal } from '../../../components';
import { TwoColContainer, TwoCol } from '../styles';
import concreteImage from '../../../resources/activities/concrete.jpg';
import { ReactComponent as ConstructionIllustration } from '../../../resources/activities/ilustrations/construction.svg';
import { ReactComponent as FactoryIllustration } from '../../../resources/activities/ilustrations/factory.svg';

const ConcreteConstructions = props => {
  const { t } = useTranslation();

  return (
    <div ref={props.activityRef}>
      <Reveal>
        <CategoryLabel laptopLeft>
          {t('pages.activities.labels.concreteConstructions')}
        </CategoryLabel>
        <TitleUnderlined center laptopLeft>
          {t('pages.activities.concreteConstructions.title')}
        </TitleUnderlined>
      </Reveal>
      <Reveal>
        <TwoColContainer main>
          <TwoCol className="main-section">
            <p>{t('pages.activities.concreteConstructions.mainText')}</p>
          </TwoCol>
          <TwoCol className="mobile-none main-section-image image-smaller">
            <img src={concreteImage} alt="concrete-constructions" />
          </TwoCol>
        </TwoColContainer>
        <TwoColContainer>
          <TwoCol>
            <ConstructionIllustration className="illustration" />
          </TwoCol>
          <TwoCol>
            <h2>{t('pages.activities.concreteConstructions.monolit.title')}</h2>
            <p>{t('pages.activities.concreteConstructions.monolit.text')}</p>
          </TwoCol>
        </TwoColContainer>
        <TwoColContainer reverse>
          <TwoCol>
            <FactoryIllustration className="illustration" />
          </TwoCol>
          <TwoCol>
            <h2>{t('pages.activities.concreteConstructions.prefab.title')}</h2>
            <p>{t('pages.activities.concreteConstructions.prefab.text')}</p>
          </TwoCol>
        </TwoColContainer>
      </Reveal>
    </div>
  );
};

export default ConcreteConstructions;
