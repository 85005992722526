import React from 'react';
import styled from 'styled-components';
import { FlexCol } from '../../../components';
import theme from '../../../theme';
import { device } from '../../../devices';

const Container = styled(FlexCol)`
  align-items: flex-start;
  text-align: start;
  
  &:hover {
    color: ${theme.color.primary};
    cursor: pointer;
    & .img {
      filter: grayscale(0);
    }
  }

  & .img {
    width: 250px;
    height: 250px;
    background-image: url("${props => props.image}");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover !important;
    filter: grayscale(100%);
    border-radius: ${theme.borderRadius}px;
    transition: all ${theme.transitionTime};
  }

  & .content {
    padding-left: 16px;
    max-width: 250px;
    word-break: break-word;
  }

  & ul {
    list-style: none;
  }

  h3 {
    margin-bottom: 8px;
    margin-top: 16px;
  }


  margin-bottom: 32px;

  @media ${device.tablet} {
    margin-bottom: 0;
    flex-basis: 30%;
    margin-left: 8px;
    margin-right: 8px;
    & .img {
      width: 200px;
      height: 200px;
    }
  }

    @media ${device.laptop} {
    margin-bottom: 0;
    flex-basis: 30%;
    margin-left: 16px;
    margin-right: 16px;
    & .img {
      width: 250px;
      height: 250px;
    }
  }
`;

const Office = props => {
  const {
    image,
    city,
    postCode,
    street,
    phoneNumber,
    email,
    handleClick
  } = props;
  return (
    <Container image={image} onClick={handleClick}>
      <div className="img" />
      <div className="content">
        <h3 className="city">{city}</h3>
        <ul>
          <li className="addr-1">{street}</li>
          <li className="addr-2">{`${postCode} ${city}`}</li>
          <li className="phone">{phoneNumber}</li>
          <li className="email">{email}</li>
        </ul>
      </div>
    </Container>
  );
};

export default Office;
