import bahno from '../../resources/about-us/team/bahno.jpg';
import butora from '../../resources/about-us/team/butora.jpg';
import dohal from '../../resources/about-us/team/dohal.jpg';
import meravy from '../../resources/about-us/team/meravy.png';
import sinka from '../../resources/about-us/team/sinka.jpg';
import slivka from '../../resources/about-us/team/slivka.jpg';
import stefkovic from '../../resources/about-us/team/stefkovic.jpg';
import jhr from '../../resources/about-us/team/JHR.jpg';
import mmi from '../../resources/about-us/team/MMI.jpg';
import mvi from '../../resources/about-us/team/MVI.jpg';
import mmk from '../../resources/about-us/team/MMK.jpg';
import defaultMan from '../../resources/about-us/team/default-man.png';

export default {
  jbu: {
    name: 'Ing. Jozef Bútora',
    phone: '+421 903 621 976',
    email: 'jozef.butora@debondt.sk',
    picture: butora
  },
  rmm: {
    name: 'Ing. René Meravý-Murárik, PhD.',
    phone: '+421 903 660 031',
    email: 'rene.meravy@debondt.sk',
    picture: meravy
  },
  tba: {
    name: 'Ing. Tomáš Bahno',
    phone: '+421 905 648 635',
    email: 'tomas.bahno@debondt.sk',
    picture: bahno
  },
  ddo: {
    name: 'Ing. Daniel Dohál',
    phone: '+421 910 101 191',
    email: 'daniel.dohal@debondt.sk',
    picture: dohal
  },
  jhr: {
    name: 'Ing. Ján Hrabovský',
    phone: '+421 903 036 032',
    email: 'jan.hrabovsky@debondt.sk',
    picture: jhr
  },
  mma: {
    name: 'Ing. Matúš Makara',
    phone: '+421 903 321 027',
    email: 'matus.makara@debondt.sk',
    picture: mmk
  },
  mmi: {
    name: 'Ing. Miroslava Mikušová, PhD.',
    phone: '+421 908 279 947',
    email: 'miroslava.mikusova@debondt.sk',
    picture: mmi
  },
  jsl: {
    name: 'Ing. Ján Slivka',
    phone: '+421 903 551 560',
    email: 'jan.slivka@debondt.sk',
    picture: slivka
  },
  dsi: {
    name: 'Ing. Dušan Šinka',
    phone: '+421 903 660 527',
    email: 'dusan.sinka@debondt.sk',
    picture: sinka
  },
  rst: {
    name: 'Ing. Roman Štefkovič',
    phone: '+421 907 455 347',
    email: 'roman.stefkovic@debondt.sk',
    picture: stefkovic
  },
  mvi: {
    name: 'Ing. Milan Vinek',
    phone: '+421 904 621 981',
    email: 'milan.vinek@debondt.sk',
    picture: mvi
  },
  fks: {
    name: 'Ing. Filip Kšiňan',
    phone: '+421 915 382 247',
    email: 'filip.ksinan@debondt.sk',
    picture: defaultMan
  }
};
