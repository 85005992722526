import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MobileFilter from './mobile/MobileFilter';
import DesktopFilter from './desktop/DesktopFilter';
import { tabletWidth } from '../../../devices';
import { useWindowSize } from '../../../hooks';

const Filter = props => {
  const {
    locations,
    services,
    serviceState,
    locationState,
    handleLocationChange,
    handleServiceChange
  } = props;
  const [isMobile, setIsMobile] = useState(true);
  const { width } = useWindowSize();
  const { t } = useTranslation();

  useEffect(() => {
    if (width >= tabletWidth) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [width]);

  if (isMobile) {
    return (
      <MobileFilter
        locations={locations}
        onLocationChange={handleLocationChange}
        services={services}
        onServiceChange={handleServiceChange}
        serviceState={serviceState}
        locationState={locationState}
        translation={t('pages.references', { returnObjects: true })}
      />
    );
  } else {
    return (
      <DesktopFilter
        locations={locations}
        onLocationChange={handleLocationChange}
        services={services}
        onServiceChange={handleServiceChange}
        serviceState={serviceState}
        locationState={locationState}
        translation={t('pages.references', { returnObjects: true })}
      />
    );
  }
};

export default Filter;
