import React from 'react';
import { Container } from './styles';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '../Checkbox';
import FormControlLabel from '../FormControlLabel';
import { TitleUnderlined } from '../../../../components';

const Filter = props => {
  const {
    locations,
    onLocationChange,
    services,
    onServiceChange,
    serviceState,
    locationState,
    translation
  } = props;

  return (
    <>
      <TitleUnderlined>{translation.title}</TitleUnderlined>
      <Container>
        <FormGroup row>
          {Object.keys(locations).map(key => (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  checked={locationState[key]}
                  onChange={onLocationChange}
                  name={key}
                />
              }
              label={locations[key]}
            />
          ))}
        </FormGroup>
        <FormGroup row>
          {Object.keys(services).map(key => (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  checked={serviceState[key]}
                  onChange={onServiceChange}
                  name={key}
                />
              }
              label={services[key]}
            />
          ))}
        </FormGroup>
      </Container>
    </>
  );
};

export default Filter;
