import styled from 'styled-components';
import { device } from '../../../devices';

export default styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  padding: 0;

  & .content {
    z-index: 2;

    & .title {
      font-family: 'Oranda-bold', serif;
      line-height: 1;
      letter-spacing: 10px;
      display: block;
      font-size: 2rem;

      @media ${device.mobileM} {
        font-size: 40px;
      }

      @media ${device.tablet} {
        font-size: 56px;
      }

      @media ${device.laptop} {
        font-size: 72px;
      }
    }

    & .subtitle {
      font-family: 'Oranda', serif;
      display: block;
      font-size: 24px;
      line-height: 1;
      text-transform: lowercase;

      @media ${device.mobileM} {
        font-size: 28px;
      }

      @media ${device.tablet} {
        font-size: 34px;
      }

      @media ${device.laptop} {
        font-size: 40px;
      }
    }
  }
`;

export const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: white;
  background-image: ${props =>
    `url(${props.image}) no-repeat center center/cover`};

  &::after {
    content: '';
    z-index: 1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
  }

  & video {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: white;

  & img {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
`;
