import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { FlexCol, TitleUnderlined, Line, Reveal } from '../../../components';
import { device } from '../../../devices';
import { useTranslation } from 'react-i18next';
import theme from '../../../theme';
import map from '../../../resources/about-us/map/map.png';

const Container = styled(FlexCol)`
  @media ${device.laptop} {
    flex-direction: row;
  }

  & .info-container {
    display: flex;
    flex-direction: column;
    flex-basis: 40%;

    & h1 {
      margin-right: auto;
      margin-left: auto;
    }

    & span.line {
      margin-left: auto;
      margin-right: auto;
    }

    @media ${device.tablet} {
      max-width: 650px;
    }

    @media ${device.laptop} {
      text-align: start;
      > span.line {
        margin-left: 0;
      }

      & h1 {
        margin-left: 0;
      }
    }
  }

  & .part-1 {
    margin-bottom: 40px;

    > a {
      text-decoration: underline;
      color: ${theme.color.secondary};
    }
  }

  & .part-2 {
    margin-top: 40px;
  }

  & .map-container {
    position: relative;
    flex: 1 0 auto;
    max-width: 850px;
    margin-top: 24px;
    overflow: hidden;

    @media ${device.mobile} {
      width: 100vw;
    }

    @media ${device.laptop} {
      flex: 1 1 60%;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transform: scale(1.8) translateX(25%);

      @media ${device.tablet} {
        transform: translate(15%);
      }
    }
  }
`;

const Company = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <div className="info-container">
        <Reveal>
          <TitleUnderlined disableUnderline>
            {t('pages.aboutUs.company.title')}
          </TitleUnderlined>
        </Reveal>
        <Reveal>
          <p className="about-company part-1">
            {parse(t('pages.aboutUs.company.part1'))}
          </p>
        </Reveal>
        <Reveal>
          <Line showAlways />
          <p className="about-company part-2">
            {parse(t('pages.aboutUs.company.part2'))}
          </p>
        </Reveal>
      </div>
      <div className="map-container">
        <img src={map} alt="map" />
      </div>
    </Container>
  );
};

export default Company;
