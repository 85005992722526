import React, { useState, useEffect } from 'react';
import Filter from './filter/Filter';
import {
  Projects,
  Section,
  PageLayoutWithoutImage,
  Reveal,
  HeadInfo
} from '../../components';
import { projects as initialProjects } from '../../data';
import { useTranslation } from 'react-i18next';

const References = () => {
  const { t } = useTranslation();
  const locations = {
    slovakia: t('locations.slovakia'),
    netherlands: t('locations.netherlands'),
    germany: t('locations.germany'),
    other: t('pages.references.other')
  };
  const services = t('services', { returnObjects: true });

  const [locationState, setLocationState] = useState({
    slovakia: true,
    netherlands: true,
    germany: true,
    other: true
  });

  const [serviceState, setServiceState] = useState({
    industrial: true,
    civil: true,
    infrastructure: true
  });

  const [projects, setProjects] = useState(initialProjects);

  const handleLocationChange = event => {
    setLocationState({
      ...locationState,
      [event.target.name]: event.target.checked
    });
  };

  const handleServiceChange = event => {
    setServiceState({
      ...serviceState,
      [event.target.name]: event.target.checked
    });
  };

  useEffect(() => {
    setProjects(
      initialProjects.filter(project => {
        if (serviceState[project.service]) {
          if (locationState[project.location]) {
            return project;
          }
          if (
            locationState.other &&
            project.location !== 'slovakia' &&
            project.location !== 'netherlands' &&
            project.location !== 'germany'
          ) {
            return project;
          }
        }
        return null;
      })
    );
  }, [serviceState, locationState]);

  return (
    <>
      <HeadInfo pathId="references" />
      <PageLayoutWithoutImage>
        <Section paddingTop="32px" paddingBottom="0px">
          <Filter
            locations={locations}
            services={services}
            locationState={locationState}
            serviceState={serviceState}
            handleLocationChange={handleLocationChange}
            handleServiceChange={handleServiceChange}
          />
        </Section>
        <Section>
          <Reveal fraction={0}>
            <Projects projects={projects} />
          </Reveal>
        </Section>
      </PageLayoutWithoutImage>
    </>
  );
};

export default References;
