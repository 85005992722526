import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import { device } from '../../devices';
import { ReactComponent as ChevronDown } from '../../resources/icons/chevron_down.svg';

const StyledButton = styled.button`
  font-family: 'Poppins', sans-serif, Helvetica, Arial;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 25px;
  padding: 8px;
  padding-left: 24px;
  padding-right: 16px;
  color: ${theme.color.white};
  background-color: ${theme.color.secondary};
  box-shadow: none;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  line-height: 1.75;
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  width: ${props => (props.wide ? '80%' : props.width)};
  margin: ${props => (props.center ? '0 auto' : 0)};

  &:hover {
    background-color: rgb(255, 128, 13);
  }

  & .button-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
  }

  & .chevron-down-icon {
    transform: rotate(-90deg);
  }

  & .end-icon {
    display: inherit;
    margin-left: 8px;
    margin-right: -4px;

    > svg {
      fill: ${theme.color.white};
    }
  }

  @media ${device.tablet} {
    width: ${props => (props.wide ? '285px' : props.width)};
  }
`;

const Button = props => {
  const { icon, children, noIcon, center, ...restProps } = props;
  return (
    <StyledButton center={center} {...restProps}>
      <span className="button-label">
        {children}
        {noIcon ? null : (
          <span className="end-icon">
            {icon ? icon : <ChevronDown className="chevron-down-icon" />}
          </span>
        )}
      </span>
    </StyledButton>
  );
};

export default Button;
