import styled from 'styled-components';
import { device } from '../../devices';

export const FlexRow = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  text-align: ${props => props.textAlign || 'justify'};
  align-items: ${props => props.alignItems || 'center'};
  justify-content: ${props => props.justifyContent || 'center'};
`;

export const FlexCol = styled(FlexRow)`
  flex-direction: column;
`;

export const MainText = styled.p`
  margin: 0 auto;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  margin-bottom: 24px;
  max-width: 650px;

  @media ${device.laptop} {
    font-size: 32px;
    line-height: 48px;
  }
`;
