import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitleUnderlined, CategoryLabel, Reveal } from '../../../components';
import { TwoColContainer, TwoCol } from '../styles';
import { ReactComponent as HomeIllustration } from '../../../resources/activities/ilustrations/home.svg';
import { ReactComponent as AbroadIllustration } from '../../../resources/activities/ilustrations/abroad.svg';

const StaticCalculations = props => {
  const { t } = useTranslation();

  return (
    <div ref={props.activityRef}>
      <Reveal>
        <CategoryLabel laptopLeft>
          {t('pages.activities.labels.staticCalculations')}
        </CategoryLabel>
        <TitleUnderlined center laptopLeft>
          {t('pages.activities.staticCalculations.title')}
        </TitleUnderlined>
      </Reveal>
      <Reveal>
        <TwoColContainer>
          <TwoCol>
            <HomeIllustration className="illustration" />
          </TwoCol>
          <TwoCol>
            <h2>{t('pages.activities.staticCalculations.home.title')}</h2>
            <p>{t('pages.activities.staticCalculations.home.text')}</p>
          </TwoCol>
        </TwoColContainer>
        <TwoColContainer reverse>
          <TwoCol>
            <AbroadIllustration className="illustration" />
          </TwoCol>
          <TwoCol>
            <h2>{t('pages.activities.staticCalculations.abroad.title')}</h2>
            <p>{t('pages.activities.staticCalculations.abroad.text')}</p>
          </TwoCol>
        </TwoColContainer>
      </Reveal>
    </div>
  );
};

export default StaticCalculations;
