import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FlexCol, Reveal } from '../../../components';
import Counter from './Counter';
import { ReactComponent as NumberIcon } from '../../../resources/icons/DEBONDT_ICON_24years.svg';
import { ReactComponent as GraphIcon } from '../../../resources/icons/DEBONDT_ICON_graph.svg';
import { ReactComponent as GuyIcon } from '../../../resources/icons/DEBONDT_ICON_guy.svg';
import { device } from '../../../devices';
import { revealTimeoutNext } from '../../../utils';

const Container = styled(FlexCol)`
  text-transform: lowercase;
  & .debondt-number {
    margin-bottom: 40px;
    word-break: break-word;
    text-align: center;
  }

  & .debondt-number:last-child {
    margin-bottom: 0px;
  }

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: initial;

    & .debondt-number {
      margin-bottom: 0;
    }
  }

  & h1 {
    margin-bottom: 0;
    margin-top: 8px;
    padding-bottom: 0;
    font-size: 64px;
    line-height: 72px;

    @media ${device.laptop} {
      font-size: 84px;
      line-height: 92px;
      margin-top: 24px;
    }
  }

  & h3 {
    color: ${props => props.theme.color.blueLight};
    font-weight: 500;
  }

  & svg {
    width: 250px;
    height: auto;
    display: flex;
  }

  & .projects-icon {
    margin-left: 20px;

    @media ${device.laptop} {
      margin-left: 40px;
    }
  }
`;

const DebondtInNumbers = props => {
  const { t } = useTranslation();
  const [firstRevealed, setFirstRevealed] = useState(false);
  const [secondRevealed, setSecondRevealed] = useState(false);
  const [thirdRevealed, setThirdRevealed] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const bounds = containerRef.current.getBoundingClientRect();
      const containerBottom = bounds.y + bounds.height;
      if (containerBottom <= window.innerHeight) setIsVisible(true);
    };

    window.addEventListener('scroll', handleScroll, false);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <Container ref={containerRef}>
      <Reveal delay={revealTimeoutNext(0)} onReveal={setFirstRevealed}>
        <FlexCol className="debondt-number">
          <NumberIcon />
          <Counter
            maxValue={25}
            startValue={0}
            timeout={75}
            start={firstRevealed && isVisible}
          />
          <h3>{t('pages.landing.debondtInNumbers.experience')}</h3>
        </FlexCol>
      </Reveal>
      <Reveal delay={revealTimeoutNext(1)} onReveal={setSecondRevealed}>
        <FlexCol className="debondt-number">
          <GraphIcon className="projects-icon" />
          <Counter
            maxValue={835}
            startValue={0}
            timeout={1}
            start={secondRevealed && isVisible}
          />
          <h3>{t('pages.landing.debondtInNumbers.projects')}</h3>
        </FlexCol>
      </Reveal>
      <Reveal delay={revealTimeoutNext(2)} onReveal={setThirdRevealed}>
        <FlexCol className="debondt-number">
          <GuyIcon />
          <Counter
            maxValue={66}
            startValue={0}
            timeout={50}
            start={thirdRevealed && isVisible}
          />
          <h3>{t('pages.landing.debondtInNumbers.employees')}</h3>
        </FlexCol>
      </Reveal>
    </Container>
  );
};

export default DebondtInNumbers;
