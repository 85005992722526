import React, { useRef, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRedirectTo } from '../../hooks';
import {
  PageLayoutWithoutImage,
  Section,
  Button,
  Modal,
  HeadInfo
} from '../../components';
import { ActivityNavigation, NavDesktop, NavMobile } from './styles';
import { getPathId } from '../../paths';
import { activities } from '../../data';
import { scrollToRef } from '../../utils';
import { activityCards } from '../../data';

import SteelConstructions from './steel-constructions/SteelConstructions';
import Bim from './complex-projects/Bim';
import AntiFire from './special-services/AntiFire';
import PassiveHouses from './special-services/PassiveHouses';
import StaticCalculations from './static-calculations/StaticCalculations';
import PowerPlant from './special-services/PowerPlant';
import ConcreteConstructions from './concrete-constructions/ConcreteConstructions';
import Counseling from './counseling/Counseling';

const Activities = props => {
  const steelConstructionsRef = useRef(null);
  const complexProjectsRef = useRef(null);
  const specialServicesRef = useRef(null);
  const staticCalculationsRef = useRef(null);
  const concreteConstructionsRef = useRef(null);
  const counselingRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();
  let location = useLocation();
  const redirect = useRedirectTo();

  useEffect(() => {
    let activityRef = getPathId(location.pathname);
    if (!activityRef) return;

    switch (activityRef) {
      case activities.complexProjects:
        scrollToRef(complexProjectsRef);
        break;
      case activities.steelConstructions:
        scrollToRef(steelConstructionsRef);
        break;
      case activities.specialServices:
        scrollToRef(specialServicesRef);
        break;
      case activities.staticCalculations:
        scrollToRef(staticCalculationsRef);
        break;
      case activities.concreteConstructions:
        scrollToRef(concreteConstructionsRef);
        break;
      case activities.counseling:
        scrollToRef(counselingRef);
        break;
      default:
        break;
    }
  }, [location]);

  const navItems = {
    [activities.steelConstructions]: {
      ref: steelConstructionsRef
    },
    [activities.complexProjects]: {
      ref: complexProjectsRef
    },
    [activities.specialServices]: {
      ref: specialServicesRef
    },
    [activities.staticCalculations]: {
      ref: staticCalculationsRef
    },
    [activities.concreteConstructions]: {
      ref: concreteConstructionsRef
    },
    [activities.counseling]: {
      ref: counselingRef
    }
  };

  return (
    <>
      <HeadInfo pathId="activities" />
      <PageLayoutWithoutImage>
        <ActivityNavigation>
          <Button
            noIcon
            center
            onClick={() => setIsModalOpen(prevIsOpen => !prevIsOpen)}
          >
            Typy aktivít
          </Button>
          <Modal
            open={isModalOpen}
            onClose={() => setIsModalOpen(prevIsOpen => !prevIsOpen)}
          >
            <NavMobile>
              <ul>
                {activityCards.map(activity => {
                  return (
                    <li
                      onClick={() => {
                        redirect(activity.type);
                        scrollToRef(navItems[activity.type].ref);
                        setIsModalOpen(false);
                      }}
                      key={activity.type}
                    >
                      {parse(
                        t(`pages.landing.activities.types.${activity.type}`)
                      )}
                    </li>
                  );
                })}
              </ul>
            </NavMobile>
          </Modal>
          <NavDesktop>
            {activityCards.map(activity => {
              return (
                <div
                  className="nav-item"
                  key={activity.type}
                  onClick={() => {
                    redirect(activity.type);
                    scrollToRef(navItems[activity.type].ref);
                  }}
                >
                  {activity.icon}
                  <p className="nav-item-text">
                    {parse(
                      t(`pages.landing.activities.types.${activity.type}`)
                    )}
                  </p>
                </div>
              );
            })}
          </NavDesktop>
        </ActivityNavigation>
        <Section>
          <StaticCalculations activityRef={staticCalculationsRef} />
        </Section>
        <Section>
          <Bim activityRef={complexProjectsRef} />
        </Section>
        <Section>
          <SteelConstructions activityRef={steelConstructionsRef} />
        </Section>
        <Section>
          <ConcreteConstructions activityRef={concreteConstructionsRef} />
        </Section>
        <Section>
          <PassiveHouses activityRef={specialServicesRef} />
        </Section>
        <Section>
          <PowerPlant />
        </Section>
        <Section>
          <AntiFire />
        </Section>
        <Section>
          <Counseling activityRef={counselingRef} />
        </Section>
      </PageLayoutWithoutImage>
    </>
  );
};

export default Activities;
