import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { device } from '../../../devices';
import { FlexCol, TitleUnderlined, Member, Reveal } from '../../../components';
import { team } from '../../../data';

const Container = styled(FlexCol)`
  & .team-container {
    align-items: flex-start;
    @media ${device.tablet} {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      &::after {
        content: '';
        width: 380px;
        max-width: 380px;
        flex-basis: 100%;
        align-self: stretch;
      }
    }
  }
`;

const Team = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Reveal>
        <TitleUnderlined center>
          {t('pages.aboutUs.team.title')}
        </TitleUnderlined>
      </Reveal>
      <Reveal>
        <FlexCol className="team-container mt-md">
          {Object.keys(team).map(memberId => (
            <Member
              name={team[memberId].name}
              position={t(`components.team.roles.${memberId}`)}
              phoneNumber={team[memberId].phone}
              email={team[memberId].email}
              picture={team[memberId].picture}
              key={memberId}
            />
          ))}
        </FlexCol>
      </Reveal>
    </Container>
  );
};

export default Team;
