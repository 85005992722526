import styled from 'styled-components';
import theme from '../../../../theme';
import FormLabel from '@material-ui/core/FormLabel';
import { styled as muiStyled } from '@material-ui/core/styles';

export const Container = styled.div`
  display: flex;
`;

export const ModalContent = styled.div`
  > .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${theme.navbarHeight.mobile};
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: ${theme.sectionPadding.mobile}px;
    padding-right: ${theme.sectionPadding.mobile}px;

    > .modal-title {
      margin-bottom: 0;
    }
  }

  > .modal-body {
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    > fieldset {
      margin-bottom: 16px;
    }
  }
`;

export const StyledFormLabel = muiStyled(FormLabel)({
  fontFamily: 'Poppins, serif',
  color: '#000000'
});
