import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import {
  FlexCol,
  TitleUnderlined,
  MainText,
  ServiceCard,
  Reveal
} from '../../../components';
import industrial from '../../../resources/landing/industrial.jpg';
import civil from '../../../resources/landing/civil.jpg';
import infrastructure from '../../../resources/landing/infrastructure.jpg';
import { device } from '../../../devices';
import { useRedirectTo } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { revealTimeoutNext } from '../../../utils';

const Services = props => {
  const redirect = useRedirectTo();
  const { t } = useTranslation();

  const services = [
    {
      id: 'industrial',
      image: industrial,
      title: t('pages.landing.services.industrial.title'),
      text: t('pages.landing.services.industrial.text'),
      onClick: () => redirect('industrial')
    },
    {
      id: 'civil',
      image: civil,
      title: t('pages.landing.services.civil.title'),
      text: t('pages.landing.services.civil.text'),
      onClick: () => redirect('civil')
    },
    {
      id: 'infrastructure',
      image: infrastructure,
      title: t('pages.landing.services.infrastructure.title'),
      text: t('pages.landing.services.infrastructure.text'),
      onClick: () => redirect('infrastructure')
    }
  ];

  const Container = styled(FlexCol)`
    & .cards-container {
      @media ${device.tablet} {
        flex-direction: row;
        align-items: stretch;
        margin-top: 40px;
      }
    }
  `;

  return (
    <Container>
      <FlexCol className="info">
        <TitleUnderlined>{t('pages.landing.services.title')}</TitleUnderlined>
        <MainText>{t('pages.landing.services.mainText')}</MainText>
      </FlexCol>
      <FlexCol className="cards-container">
        {services.map((service, index) => (
          <Reveal delay={revealTimeoutNext(index)} flex key={index}>
            <ServiceCard
              text={service.text}
              image={service.image}
              title={parse(service.title)}
              onClick={service.onClick}
            />
          </Reveal>
        ))}
      </FlexCol>
    </Container>
  );
};

export default Services;
