import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetLinks } from '../../../hooks';
import { NavLink } from 'react-router-dom';
import { BurgerIcon, MobileMenu } from './mobile';
import { DesktopMenu } from './desktop';
import { NavbarContainer } from './styles';
import { languages } from '../../../data';
import logo from '../../../resources/debondt-logo.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentLocale, setCurrentLocale] = useState('sk');
  const [locale, setLocale] = useState(languages);
  const links = useGetLinks();
  const { i18n } = useTranslation();

  const handleLocaleChange = language => {
    setCurrentLocale(language);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    setLocale(languages.filter(lng => lng !== currentLocale));
  }, [currentLocale]);

  return (
    <>
      <NavbarContainer>
        <div className="content">
          <div className="brand">
            <NavLink to={links.landing}>
              <img src={logo} alt="debondt-logo" />
            </NavLink>
          </div>
          <DesktopMenu
            locale={currentLocale}
            changeLocale={handleLocaleChange}
            languages={locale}
            links={links}
          />
          <BurgerIcon
            navbarState={isOpen}
            onClick={() => setIsOpen(prevOpen => !prevOpen)}
          />
        </div>
      </NavbarContainer>
      <MobileMenu
        navbarState={isOpen}
        handleNavbar={() => setIsOpen(prevOpen => !prevOpen)}
        locale={currentLocale}
        changeLocale={handleLocaleChange}
        languages={locale}
        links={links}
      />
    </>
  );
};

export default Navbar;
