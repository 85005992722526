import React, { useState, useEffect } from 'react';

const Counter = props => {
  const { maxValue, startValue, timeout, start } = props;
  const initialTimeout = timeout || 1;
  const [count, setCount] = useState(startValue);

  const increment = () => {
    if (count < maxValue) {
      setCount(prevCount => prevCount + 1);
    }
  };

  useEffect(() => {
    if (start) {
      if (count > maxValue - 5) {
        setTimeout(() => {
          increment();
        }, 200);
      } else {
        setTimeout(() => {
          increment();
        }, initialTimeout);
      }
    }
  });

  return <h1>{count}</h1>;
};

export default Counter;
