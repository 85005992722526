import React from 'react';
import OuterContainer from './styles';
import theme from '../../theme';

const Section = props => {
  const { bgColor, color, paddingBottom, paddingTop, fullWidth } = props;

  return (
    <OuterContainer
      bgColor={bgColor || theme.color.white}
      color={color || theme.color.black}
      paddingBottom={paddingBottom}
      paddingTop={paddingTop}
      fullWidth={fullWidth}
    >
      <div className="inner-container">{props.children}</div>
    </OuterContainer>
  );
};

export default Section;
