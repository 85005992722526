import MuiCheckbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import theme from '../../../theme';

const checkBoxStyles = muiTheme => ({
  root: {
    '&$checked': {
      color: `${theme.color.secondary}`
    }
  },
  checked: {}
});

export default withStyles(checkBoxStyles)(MuiCheckbox);
