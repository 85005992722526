import React from 'react';
import { useRedirectTo } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  FlexCol,
  TitleUnderlined,
  MainText,
  ButtonMore
} from '../../../components';

const Container = styled(FlexCol)``;

const About = props => {
  const { sectionRef } = props;
  const redirect = useRedirectTo();
  const { t } = useTranslation();

  return (
    <Container ref={sectionRef}>
      <TitleUnderlined>{t('pages.landing.about.title')}</TitleUnderlined>
      <MainText>{t('pages.landing.about.mainText')}</MainText>
      <ButtonMore wide onClick={() => redirect('about')} enableLongText />
    </Container>
  );
};

export default About;
