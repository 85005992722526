import React from 'react';
import {
  PageLayout,
  Section,
  TitleUnderlined,
  MainText,
  Projects,
  Reveal,
  HeadInfo
} from '../../../components';
import bgImage from '../../../resources/services/n18.JPG';
import { getProjectsByService } from '../../../utils';
import { projects } from '../../../data';
import { useTranslation } from 'react-i18next';

const Infrastructure = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeadInfo pathId="infrastructure" />
      <PageLayout
        image={bgImage}
        title={t('pages.infrastructure.top.title')}
        description={t('pages.infrastructure.top.desc')}
      >
        <Reveal>
          <Section>
            <TitleUnderlined disableTitle lineShowAlways />
            <MainText>{t('pages.infrastructure.mainText')}</MainText>
          </Section>
          <TitleUnderlined>{t('components.projects.title')}</TitleUnderlined>
        </Reveal>
        <Reveal fraction={0}>
          <Projects
            projects={getProjectsByService(projects, 'infrastructure')}
          />
        </Reveal>
      </PageLayout>
    </>
  );
};

export default Infrastructure;
