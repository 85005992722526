import React from 'react';
import { useTranslation } from 'react-i18next';
import { TitleUnderlined, CategoryLabel, Reveal } from '../../../components';
import {
  TwoColContainer,
  TwoCol,
  ThreeColContainer,
  ThreeCol
} from '../styles';
import { ReactComponent as KnowledgeIllustration } from '../../../resources/activities/ilustrations/knowledge.svg';
import { ReactComponent as ExperienceIllustration } from '../../../resources/activities/ilustrations/experience.svg';
import { ReactComponent as ForwardIllustration } from '../../../resources/activities/ilustrations/forward.svg';
import schoolImage from '../../../resources/activities/skola-vizualizacia.jpg';
import { revealTimeoutNext } from '../../../utils';

const Bim = props => {
  const { t } = useTranslation();

  return (
    <div ref={props.activityRef}>
      <Reveal>
        <CategoryLabel laptopLeft>
          {t('pages.activities.labels.complexProjects')}
        </CategoryLabel>
        <TitleUnderlined center laptopLeft>
          {t('pages.activities.bim.title')}
        </TitleUnderlined>
      </Reveal>
      <Reveal>
        <TwoColContainer main>
          <TwoCol className="main-section">
            <p>{t('pages.activities.bim.mainText')}</p>
          </TwoCol>
          <TwoCol className="mobile-none main-section-image">
            <img src={schoolImage} alt="school-visualization" />
          </TwoCol>
        </TwoColContainer>
      </Reveal>
      <ThreeColContainer>
        <Reveal delay={revealTimeoutNext(0)}>
          <ThreeCol>
            <KnowledgeIllustration className="illustration" />
            <h2>{t('pages.activities.bim.knowledge.title')}</h2>
            <p>{t('pages.activities.bim.knowledge.text')}</p>
          </ThreeCol>
        </Reveal>
        <Reveal delay={revealTimeoutNext(1)}>
          <ThreeCol>
            <ExperienceIllustration className="illustration" />
            <h2>{t('pages.activities.bim.experience.title')}</h2>
            <p>{t('pages.activities.bim.experience.text')}</p>
          </ThreeCol>
        </Reveal>
        <Reveal delay={revealTimeoutNext(2)}>
          <ThreeCol>
            <ForwardIllustration className="illustration-not-custom" />
            <h2>{t('pages.activities.bim.progress.title')}</h2>
            <p>{t('pages.activities.bim.progress.text')}</p>
          </ThreeCol>
        </Reveal>
      </ThreeColContainer>
    </div>
  );
};

export default Bim;
