import React from 'react';
import Line from '../../line/Line';
import CardContainer from './styles';
import parse from 'html-react-parser';

const EnumCard = props => {
  const { list, title, bgColor } = props;

  return (
    <CardContainer className="base-card" bgColor={bgColor}>
      <div className="card-body">
        <h2 className="card-title">{parse(title)}</h2>
        <Line showAlways />
        <div className="enum">
          <ul>
            {list.map((item, id) => (
              <li key={id}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </CardContainer>
  );
};

export default EnumCard;
