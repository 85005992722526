import styled from 'styled-components';
import theme from '../../theme';
import { device } from '../../devices';

const height = '100vh';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.color.white};

  & .top-container {
    width: 100%;
    height: ${props => (props.imageHeight ? props.imageHeight : height)};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    color: ${theme.color.white};
    padding: 0;
  }

  & .background-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${props => (props.imageHeight ? props.imageHeight : height)};
    overflow: hidden;

    /* grey overflow */
    &::after {
      content: '';
      z-index: 1;
      height: ${height};
      width: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
    }
  }

  & .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-position: ${props =>
      props.imagePosition ? props.imagePosition : 'center 60px'};
    background-size: cover !important;
    background-image: ${props => `url(${props.image})`};
    transform: ${props => (props.imageFlip ? 'scaleX(-1)' : 'none')};
    transform: scale(1.01);
    background-repeat: no-repeat;
  }

  & .background-image.about-us {
    @media ${device.laptop} {
      background-position: 50% 30%;
    }

    @media (min-width: 600px) {
      transform: translateY(50px);
    }
  }

  & .top-content {
    z-index: 2;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    margin-top: ${theme.navbarHeight.mobile}px;

    @media ${device.tablet} {
      margin-top: ${theme.navbarHeight.tablet}px;
    }

    @media ${device.laptop} {
      text-align: left;
      align-items: flex-start;
      padding-left: 8.125rem;

      > .line {
        margin-right: auto;
      }
    }
  }

  & .top-title {
    display: block;
    font-family: 'Poppins', serif;
    font-size: 36px;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 40px;
    text-transform: uppercase;

    @media ${device.tablet} {
      font-size: 44px;
      max-width: 500px;
    }

    @media ${device.laptop} {
      margin-right: auto;
      font-size: 56px;
    }
  }

  & .top-description {
    font-family: 'Poppins', sans-serif, Helvetica, Arial;
    margin-top: 40px;
    max-width: 400px;

    @media ${device.tablet} {
      font-size: 18px;
      max-width: 500px;
    }

    @media ${device.laptop} {
      font-size: 20px;
    }
  }

  & .content {
    overflow: hidden;
  }
`;
