import React from 'react';
import parse from 'html-react-parser';
import Card from './styles';

const ActivityCard = props => {
  const { title, icon, handleClick } = props;
  return (
    <Card className="base-card" onClick={handleClick}>
      {icon}
      <span className="title">{parse(title)}</span>
    </Card>
  );
};

export default ActivityCard;
