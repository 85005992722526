import React from 'react';
import { Container } from './styles';

const Member = props => {
  const { name, position, email, phoneNumber, picture } = props;

  const formatPhoneNumber = number => {
    return number;
  };

  return (
    <Container>
      <img className="picture person-picture" src={picture} alt={`${name}`} />
      <div className="info">
        <h3 className="name">{name}</h3>
        <p className="position label">{position}</p>
        <a href={`mailto:${email}`} className="contact">
          {email}
        </a>
        <a href={`tel:${phoneNumber}`} className="contact">
          {formatPhoneNumber(phoneNumber)}
        </a>
      </div>
    </Container>
  );
};

export default Member;
