import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import theme from '../../../../../theme';
import { device } from '../../../../../devices';

const Container = styled.div`
  background-color: ${theme.color.white};
  position: fixed;
  left: 0;
  right: 0;
  top: ${theme.navbarHeight.mobile}px;
  width: 100%;
  height: 100vh;
  z-index: 4;
  padding: 40px;

  & .nav-mobile {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;

    & > li:not(:first-child) {
      margin-top: 24px;
    }

    & > li {
      font-family: 'Barlow Condensed', sans-serif;
      font-weight: 600;
      color: #123f73;
      position: relative;
      text-transform: uppercase;
      font-size: 30px;
      line-height: 34px;
      display: block;
      width: 100%;
    }
  }

  @media ${device.tablet} {
    top: ${theme.navbarHeight.tablet}px;
  }
`;

const Locale = styled.ul`
  display: flex;
  justify-content: center;
  font-family: 'Poppins', serif;
  font-size: 20px;
  font-weight: 400;
  color: ${theme.color.primary};

  > li {
    text-transform: uppercase;
    display: inline-flex;
    cursor: pointer;
  }

  & > li:not(:first-child) {
    margin-left: 24px;
  }

  & > li.active {
    color: ${theme.color.secondary};
  }
`;

const MobileMenu = props => {
  const {
    navbarState,
    handleNavbar,
    locale,
    changeLocale,
    languages,
    links
  } = props;
  const { t } = useTranslation();

  if (!navbarState) return null;

  return (
    <Container>
      <ul className="nav-mobile">
        <li>
          <NavLink
            to={links.about}
            activeClassName="active-link"
            onClick={() => handleNavbar(prevState => !prevState)}
          >
            {t('components.navigation.about')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={links.industrial}
            activeClassName="active-link"
            onClick={() => handleNavbar(prevState => !prevState)}
          >
            {t('services.industrial')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={links.civil}
            activeClassName="active-link"
            onClick={() => handleNavbar(prevState => !prevState)}
          >
            {t('services.civil')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={links.infrastructure}
            activeClassName="active-link"
            onClick={() => handleNavbar(prevState => !prevState)}
          >
            {t('services.infrastructure')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={links.activities}
            activeClassName="active-link"
            onClick={() => handleNavbar(prevState => !prevState)}
          >
            {t('components.navigation.activities')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={links.references}
            activeClassName="active-link"
            onClick={() => handleNavbar(prevState => !prevState)}
          >
            {t('components.navigation.references')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={links.contact}
            activeClassName="active-link"
            onClick={() => handleNavbar(prevState => !prevState)}
          >
            {t('components.navigation.contact')}
          </NavLink>
        </li>
      </ul>
      <Locale>
        {languages.map(lng => (
          <li
            data-lng={lng}
            className={lng === locale ? 'active' : ''}
            onClick={e => changeLocale(e.target.dataset.lng)}
            key={lng}
          >
            {lng}
          </li>
        ))}
      </Locale>
    </Container>
  );
};

export default MobileMenu;
