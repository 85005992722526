import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { revealTimeout } from '../../theme';
import { useWindowSize } from '../../hooks';
import { laptopWidth } from '../../devices';

const NOOP = () => {};

const Reveal = props => {
  const { children, delay, onReveal = NOOP, flex, fraction } = props;
  const { width } = useWindowSize();
  const [shouldFade, setShouldFade] = useState(false);

  const afterReveal = () => onReveal(true);

  useEffect(() => {
    onReveal(true);
    if (width < laptopWidth) {
      setShouldFade(false);
    } else {
      setShouldFade(true);
    }
  }, [shouldFade, onReveal, width]);

  if (!shouldFade) {
    return children;
  } else {
    return (
      <Fade
        delay={delay ? delay : revealTimeout.initial}
        onReveal={afterReveal}
        fraction={fraction}
      >
        {flex ? <div style={{ display: 'flex' }}>{children}</div> : children}
      </Fade>
    );
  }
};

export default Reveal;
