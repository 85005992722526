import { importAll } from '../../utils';
import activities from '../activities/activities';

export default [
  {
    name: 'tnPedestrianZone',
    translation: 'projects.tnPedestrianZone',
    location: 'slovakia',
    service: 'civil',
    activities: activities.complexProjects,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/tn-pedestrian-zone/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/tn-pedestrian-zone/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/tn-pedestrian-zone/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/tn-pedestrian-zone/gallery-thumbnail'
      )
    ),
    bgPosition: 'top center',
    sources:
      'Architektonická štúdia  Trencin_revitalizacia_pesej_zony - A schválená.pdf'
  },
  {
    name: 'wonderWoods',
    translation: 'projects.wonderWoods',
    location: 'netherlands',
    service: 'civil',
    activities: activities.steelConstructions,
    personInChargeId: 'jsl',
    bg: require.context(
      '../../resources/projects/wonder-woods/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/wonder-woods/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/wonder-woods/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/wonder-woods/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'multipurposeSportsHallBratislava',
    translation: 'projects.sportsHallBa',
    location: 'slovakia',
    service: 'civil',
    activities: activities.steelConstructions,
    personInChargeId: 'ddo',
    bg: require.context(
      '../../resources/projects/sports-hall-ba/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/sports-hall-ba/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/sports-hall-ba/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/sports-hall-ba/gallery-thumbnail'
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'ernstprofil',
    translation: 'projects.ernstprofil',
    location: 'slovakia',
    service: 'industrial',
    activities: activities.steelConstructions,
    personInChargeId: 'ddo',
    bg: require.context(
      '../../resources/projects/ernstprofil/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/ernstprofil/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/ernstprofil/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/ernstprofil/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'sandResortBa',
    translation: 'projects.sandResortBa',
    location: 'slovakia',
    service: 'civil',
    activities: activities.complexProjects,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/sandResortBa/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/sandResortBa/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/sandResortBa/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/sandResortBa/gallery-thumbnail')
    ),
    bgPosition: 'top center',
    sources:
      'https://www.agaty.sk/o-projekte/, https://www.agaty.sk/dom-alebo-byt-pre-ake-byvanie-sa-rozhodnut/, https://www.agaty.sk/dom-alebo-byt-pre-ake-byvanie-sa-rozhodnut/'
  },
  {
    name: 'heneken',
    translation: 'projects.heneken',
    location: 'slovakia',
    service: 'industrial',
    activities: activities.steelConstructions,
    personInChargeId: 'ddo',
    bg: require.context(
      '../../resources/projects/heneken/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/heneken/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/heneken/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/heneken/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'ikeaMalackyModern',
    translation: 'projects.ikeaMalackyModern',
    location: 'slovakia',
    service: 'industrial',
    activities: activities.steelConstructions,
    personInChargeId: 'fks',
    bg: require.context(
      '../../resources/projects/ikeaMalackyModern/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/ikeaMalackyModern/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/ikeaMalackyModern/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/ikeaMalackyModern/gallery-thumbnail'
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'tramDepoKosice',
    translation: 'projects.tramDepoKosice',
    location: 'slovakia',
    service: 'industrial',
    activities: activities.steelConstructions,
    personInChargeId: 'fks',
    bg: require.context(
      '../../resources/projects/tramDepoKosice/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/tramDepoKosice/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/tramDepoKosice/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/tramDepoKosice/gallery-thumbnail'
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'zs-bs',
    translation: 'projects.zs-bs',
    location: 'slovakia',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/zs-bs/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/zs-bs/thumbnail',
      false,
      /\.(png|jpe?g|JPE?G|jpe?g|JPE?G)$/
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/zs-bs/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/zs-bs/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'center center'
  },
  {
    name: 'brezovy-haj',
    translation: 'projects.brezovy-haj',
    location: 'slovakia',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/brezovy-haj/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/brezovy-haj/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/brezovy-haj/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/brezovy-haj/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'dahlemerweg',
    translation: 'projects.dahlemerweg',
    location: 'germany',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/dahlemerweg/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/dahlemerweg/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/dahlemerweg/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/dahlemerweg/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'metronom',
    translation: 'projects.metronom',
    location: 'germany',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/metronom/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/metronom/thumbnail',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/metronom/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/metronom/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'bottova',
    translation: 'projects.bottova',
    location: 'slovakia',
    service: 'civil',
    activity: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/bottova/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/bottova/thumbnail',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/bottova/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/bottova/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'cca',
    translation: 'projects.cca',
    location: 'netherlands',
    service: 'civil',
    personInChargeId: 'jsl',
    bg: require.context(
      '../../resources/projects/cca/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/cca/thumbnail',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/cca/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/cca/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'hotel-damian',
    translation: 'projects.hotel-damian',
    location: 'slovakia',
    service: 'civil',
    activities: activities.steelConstructions,
    personInChargeId: 'ddo',
    bg: require.context(
      '../../resources/projects/hotel-damian/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/hotel-damian/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/hotel-damian/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/hotel-damian/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'matusova',
    translation: 'projects.matusova',
    location: 'slovakia',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/matusova/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/matusova/thumbnail',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/matusova/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/matusova/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  // {
  //   name: 'pulse-berlin',
  //   translation: 'projects.pulse-berlin',
  //   location: 'germany',
  //   service: 'civil',
  //   activities: activities.steelConstructions,
  //   personInChargeId: 'ddo',
  //   bg: require.context(
  //     '../../resources/projects/pulse-berlin/bg',
  //     false,
  //     /\.(png|jpe?g|JPE?G)$/
  //   ),
  //   thumbnail: require.context(
  //     '../../resources/projects/pulse-berlin/thumbnail'
  //   ),
  //   gallery: importAll(
  //     require.context(
  //       '../../resources/projects/pulse-berlin/gallery',
  //       false,
  //       /\.(png|jpe?g|JPE?G)$/
  //     )
  //   ),
  //   galleryThumbnail: importAll(
  //     require.context(
  //       '../../resources/projects/pulse-berlin/gallery-thumbnail',
  //       false,
  //       /\.(png|jpe?g|JPE?G)$/
  //     )
  //   ),
  //   bgPosition: 'top center'
  // },
  // {
  //   name: 'strelnica',
  //   translation: 'projects.strelnica',
  //   location: 'slovakia',
  //   service: 'civil',
  //   activities: activities.staticCalculations,
  //   personInChargeId: 'tba',
  //   bg: require.context(
  //     '../../resources/projects/strelnica/bg',
  //     false,
  //     /\.(png|jpe?g|JPE?G)$/
  //   ),
  //   thumbnail: require.context(
  //     '../../resources/projects/strelnica/thumbnail',
  //     false,
  //     /\.(png|jpe?g|JPE?G)$/
  //   ),
  //   gallery: importAll(
  //     require.context(
  //       '../../resources/projects/strelnica/gallery',
  //       false,
  //       /\.(png|jpe?g|JPE?G)$/
  //     )
  //   ),
  //   galleryThumbnail: importAll(
  //     require.context(
  //       '../../resources/projects/strelnica/gallery-thumbnail',
  //       false,
  //       /\.(png|jpe?g|JPE?G)$/
  //     )
  //   ),
  //   bgPosition: 'top center'
  // },
  {
    name: 'itab',
    translation: 'projects.itab',
    location: 'czechia',
    service: 'industrial',
    activities: activities.steelConstructions,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/itab/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/itab/thumbnail',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/itab/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/itab/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'mochovce',
    translation: 'projects.mochovce',
    location: 'slovakia',
    service: 'industrial',
    activities: activities.specialServices,
    personInChargeId: 'rst',
    bg: require.context(
      '../../resources/projects/mochovce/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/mochovce/thumbnail',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/mochovce/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/mochovce/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'modernizacja',
    translation: 'projects.modernizacja',
    location: 'bosnia',
    service: 'industrial',
    activities: activities.steelConstructions,
    personInChargeId: 'ddo',
    bg: require.context(
      '../../resources/projects/modernizacja/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/modernizacja/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/modernizacja/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/modernizacja/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'schelklingen',
    translation: 'projects.schelklingen',
    location: 'germany',
    service: 'industrial',
    activities: activities.staticCalculations,
    personInChargeId: 'ddo',
    bg: require.context(
      '../../resources/projects/schelklingen/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/schelklingen/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/schelklingen/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/schelklingen/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'ikea',
    translation: 'projects.ikea',
    location: 'slovakia',
    service: 'industrial',
    activities: activities.steelConstructions,
    personInChargeId: 'ddo',
    bg: require.context(
      '../../resources/projects/ikea/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/ikea/thumbnail',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/ikea/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/ikea/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'tatravagonka',
    translation: 'projects.tatravagonka',
    location: 'slovakia',
    service: 'industrial',
    activities: activities.steelConstructions,
    personInChargeId: 'ddo',
    bg: require.context(
      '../../resources/projects/tatravagonka/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/tatravagonka/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/tatravagonka/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/tatravagonka/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'wwt',
    translation: 'projects.wwt',
    location: 'netherlands',
    service: 'industrial',
    activities: activities.steelConstructions,
    personInChargeId: 'ddo',
    bg: require.context(
      '../../resources/projects/wwt/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/wwt/thumbnail',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/wwt/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/wwt/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'belvederelaan',
    translation: 'projects.belvederelaan',
    location: 'netherlands',
    service: 'infrastructure',
    activities: activities.concreteConstructions,
    personInChargeId: 'jsl',
    bg: require.context(
      '../../resources/projects/belvederelaan/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/belvederelaan/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/belvederelaan/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/belvederelaan/gallery-thumbnail'
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'gcb-utrecht',
    translation: 'projects.gcb-utrecht',
    location: 'netherlands',
    service: 'infrastructure',
    activities: activities.concreteConstructions,
    personInChargeId: 'jsl',
    bg: require.context(
      '../../resources/projects/gcb-utrecht/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/gcb-utrecht/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/gcb-utrecht/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/gcb-utrecht/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'n18',
    translation: 'projects.n18',
    location: 'netherlands',
    service: 'infrastructure',
    activities: activities.concreteConstructions,
    personInChargeId: 'jsl',
    bg: require.context(
      '../../resources/projects/n18/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/n18/thumbnail',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/n18/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/n18/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'n381-brug',
    translation: 'projects.n381-brug',
    location: 'netherlands',
    service: 'infrastructure',
    activities: activities.concreteConstructions,
    personInChargeId: 'jsl',
    bg: require.context(
      '../../resources/projects/n381-brug/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/n381-brug/thumbnail',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/n381-brug/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/n381-brug/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'n381-odg',
    translation: 'projects.n381-odg',
    location: 'netherlands',
    service: 'infrastructure',
    activities: activities.concreteConstructions,
    personInChargeId: 'jsl',
    bg: require.context(
      '../../resources/projects/n381-odg/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/n381-odg/thumbnail',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/n381-odg/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/n381-odg/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'odg-goes',
    translation: 'projects.odg-goes',
    location: 'netherlands',
    service: 'infrastructure',
    activities: activities.concreteConstructions,
    personInChargeId: 'jsl',
    bg: require.context(
      '../../resources/projects/odg-goes/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/odg-goes/thumbnail',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/odg-goes/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/odg-goes/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'ztg-ijmond',
    translation: 'projects.ztg-ijmond',
    location: 'netherlands',
    service: 'infrastructure',
    activities: activities.concreteConstructions,
    personInChargeId: 'jsl',
    bg: require.context(
      '../../resources/projects/ztg-ijmond/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/ztg-ijmond/thumbnail',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/ztg-ijmond/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/ztg-ijmond/gallery-thumbnail',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'zwolle-herfte',
    translation: 'projects.zwolle-herfte',
    location: 'netherlands',
    service: 'infrastructure',
    activities: activities.concreteConstructions,
    personInChargeId: 'jsl',
    bg: require.context(
      '../../resources/projects/zwolle-herfte/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/zwolle-herfte/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/zwolle-herfte/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/zwolle-herfte/gallery-thumbnail'
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'vodaren',
    translation: 'projects.vodaren',
    location: 'slovakia',
    service: 'civil',
    activities: activities.concreteConstructions,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/vodaren/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/vodaren/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/vodaren/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/vodaren/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'riviera',
    translation: 'projects.riviera',
    location: 'slovakia',
    service: 'civil',
    activities: activities.concreteConstructions,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/riviera/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/riviera/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/riviera/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/riviera/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'vicat',
    translation: 'projects.vicat',
    location: 'senegal',
    service: 'industrial',
    activities: activities.concreteConstructions,
    personInChargeId: 'ddo',
    bg: require.context(
      '../../resources/projects/vicat/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/vicat/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/vicat/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/vicat/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'abDebondt',
    translation: 'projects.abDebondt',
    location: 'slovakia',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/vicat/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/abDebondt/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/abDebondt/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/abDebondt/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'dnv',
    translation: 'projects.dnv',
    location: 'slovakia',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/dnv/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/dnv/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/dnv/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/dnv/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'justPlay',
    translation: 'projects.justPlay',
    location: 'slovakia',
    service: 'industrial',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/justPlay/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/justPlay/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/justPlay/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/justPlay/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'petrovany',
    translation: 'projects.petrovany',
    location: 'slovakia',
    service: 'industrial',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/petrovany/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/petrovany/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/petrovany/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/petrovany/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'nfs',
    translation: 'projects.nfs',
    location: 'slovakia',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/nfs/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/nfs/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/nfs/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/nfs/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'panorama',
    translation: 'projects.panorama',
    location: 'slovakia',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/panorama/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/panorama/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/panorama/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/panorama/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'amsterdamSymphony',
    translation: 'projects.amsterdamSymphony',
    location: 'netherlands',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'jsl',
    bg: require.context(
      '../../resources/projects/amsterdamSymphony/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/amsterdamSymphony/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/amsterdamSymphony/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/amsterdamSymphony/gallery-thumbnail'
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'parkeerLelystad',
    translation: 'projects.parkeerLelystad',
    location: 'netherlands',
    service: 'industrial',
    activities: activities.staticCalculations,
    personInChargeId: 'jsl',
    bg: require.context(
      '../../resources/projects/parkeerLelystad/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/parkeerLelystad/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/parkeerLelystad/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/parkeerLelystad/gallery-thumbnail'
      )
    ),
    bgPosition: 'top center'
  },
  {
    name: 'validEindhoven',
    translation: 'projects.validEindhoven',
    location: 'netherlands',
    service: 'infrastructure',
    activities: activities.staticCalculations,
    personInChargeId: 'jsl',
    bg: require.context(
      '../../resources/projects/validEindhoven/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/validEindhoven/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/validEindhoven/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/validEindhoven/gallery-thumbnail'
      )
    ),
    bgPosition: 'top center'
  },
  // {
  //   name: 'rabobankAlkkmaar',
  //   translation: 'projects.rabobankAlkkmaar',
  //   location: 'netherlands',
  //   service: 'civil',
  //   activities: activities.staticCalculations,
  //   personInChargeId: 'jsl',
  //   bg: require.context(
  //     '../../resources/projects/rabobankAlkkmaar/bg',
  //     false,
  //     /\.(png|jpe?g|JPE?G)$/
  //   ),
  //   thumbnail: require.context(
  //     '../../resources/projects/rabobankAlkkmaar/thumbnail'
  //   ),
  //   // gallery: importAll(
  //   //   require.context(
  //   //     '../../resources/projects/rabobankAlkkmaar/gallery',
  //   //     false,
  //   //     /\.(png|jpe?g|JPE?G)$/
  //   //   )
  //   // ),
  //   // galleryThumbnail: importAll(
  //   //   require.context(
  //   //     '../../resources/projects/rabobankAlkkmaar/gallery-thumbnail'
  //   //   )
  //   // ),
  //   bgPosition: 'top center'
  // },
  {
    name: 'terminalRotterdam',
    translation: 'projects.terminalRotterdam',
    location: 'netherlands',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'jsl',
    bg: require.context(
      '../../resources/projects/terminalRotterdam/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/terminalRotterdam/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/terminalRotterdam/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context(
        '../../resources/projects/terminalRotterdam/gallery-thumbnail'
      )
    ),
    bgPosition: 'top center'
  },
  // {
  //   name: 'tramontane',
  //   translation: 'projects.tramontane',
  //   location: 'netherlands',
  //   service: 'civil',
  //   activities: activities.staticCalculations,
  //   personInChargeId: 'jsl',
  //   bg: require.context(
  //     '../../resources/projects/tramontane/bg',
  //     false,
  //     /\.(png|jpe?g|JPE?G)$/
  //   ),
  //   thumbnail: require.context('../../resources/projects/tramontane/thumbnail'),
  //   // gallery: importAll(
  //   //   require.context(
  //   //     '../../resources/projects/tramontane/gallery',
  //   //     false,
  //   //     /\.(png|jpe?g|JPE?G)$/
  //   //   )
  //   // ),
  //   // galleryThumbnail: importAll(
  //   //   require.context('../../resources/projects/tramontane/gallery-thumbnail')
  //   // ),
  //   bgPosition: 'top center'
  // },
  {
    name: 'ruhrbania',
    translation: 'projects.ruhrbania',
    location: 'germany',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'jsl',
    bg: require.context(
      '../../resources/projects/ruhrbania/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/ruhrbania/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/ruhrbania/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/ruhrbania/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'bonarka',
    translation: 'projects.bonarka',
    location: 'poland',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'dsi',
    bg: require.context(
      '../../resources/projects/bonarka/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/bonarka/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/bonarka/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/bonarka/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'stadionTt',
    translation: 'projects.stadionTt',
    location: 'slovakia',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'dsi',
    bg: require.context(
      '../../resources/projects/stadionTt/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/stadionTt/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/stadionTt/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/stadionTt/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'hiltonBa',
    translation: 'projects.hiltonBa',
    location: 'slovakia',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'dsi',
    bg: require.context(
      '../../resources/projects/hiltonBa/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/hiltonBa/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/hiltonBa/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/hiltonBa/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'kappusHoefe',
    translation: 'projects.kappusHoefe',
    location: 'germany',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'dsi',
    bg: require.context(
      '../../resources/projects/kappusHoefe/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/kappusHoefe/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/kappusHoefe/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/kappusHoefe/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'ncLaugaricio',
    translation: 'projects.ncLaugaricio',
    location: 'slovakia',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'dsi',
    bg: require.context(
      '../../resources/projects/ncLaugaricio/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/ncLaugaricio/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/ncLaugaricio/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/ncLaugaricio/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'zochova',
    translation: 'projects.zochova',
    location: 'slovakia',
    service: 'civil',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/zochova/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/zochova/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/zochova/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/zochova/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'cloetta',
    translation: 'projects.cloetta',
    location: 'slovakia',
    service: 'industrial',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/cloetta/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/cloetta/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/cloetta/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/cloetta/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'ikeaMalacky',
    translation: 'projects.ikeaMalacky',
    location: 'slovakia',
    service: 'industrial',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/ikeaMalacky/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context(
      '../../resources/projects/ikeaMalacky/thumbnail'
    ),
    gallery: importAll(
      require.context(
        '../../resources/projects/ikeaMalacky/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/ikeaMalacky/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'leafHall',
    translation: 'projects.leafHall',
    location: 'slovakia',
    service: 'industrial',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/leafHall/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/leafHall/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/leafHall/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/leafHall/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'spartan',
    translation: 'projects.spartan',
    location: 'slovakia',
    service: 'industrial',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/spartan/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/spartan/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/spartan/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/spartan/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'leafCampus',
    translation: 'projects.leafCampus',
    location: 'slovakia',
    service: 'industrial',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/leafCampus/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/leafCampus/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/leafCampus/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/leafCampus/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  },
  {
    name: 'zts',
    translation: 'projects.zts',
    location: 'slovakia',
    service: 'industrial',
    activities: activities.staticCalculations,
    personInChargeId: 'tba',
    bg: require.context(
      '../../resources/projects/zts/bg',
      false,
      /\.(png|jpe?g|JPE?G)$/
    ),
    thumbnail: require.context('../../resources/projects/zts/thumbnail'),
    gallery: importAll(
      require.context(
        '../../resources/projects/zts/gallery',
        false,
        /\.(png|jpe?g|JPE?G)$/
      )
    ),
    galleryThumbnail: importAll(
      require.context('../../resources/projects/zts/gallery-thumbnail')
    ),
    bgPosition: 'top center'
  }
];
