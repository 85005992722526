import React from 'react';
import { useRedirectTo } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  TitleUnderlined,
  MainText,
  FlexCol,
  ActivityCard,
  Reveal
} from '../../../components';
import { activityCards } from '../../../data';
import { device } from '../../../devices';

const Container = styled(FlexCol)`
  & .cards-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -16px;
    justify-content: center;
    max-width: 648px;

    @media ${device.tablet} {
      margin-top: 40px;
    }
  }
`;

const Activities = props => {
  const redirect = useRedirectTo();
  const { t } = useTranslation();

  return (
    <Container>
      <Reveal>
        <TitleUnderlined>{t('pages.landing.activities.title')}</TitleUnderlined>
        <MainText>{t('pages.landing.activities.mainText')}</MainText>
      </Reveal>
      <Reveal>
        <div className="cards-container">
          {activityCards.map(activity => (
            <ActivityCard
              key={activity.type}
              title={t(`pages.landing.activities.types.${activity.type}`)}
              icon={activity.icon}
              type={activity.type}
              handleClick={() => redirect(activity.type)}
            />
          ))}
        </div>
      </Reveal>
    </Container>
  );
};

export default Activities;
