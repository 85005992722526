import React, { useState, useEffect } from 'react';
import { useWindowSize } from '../../hooks';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { ReactComponent as ChevronDown } from '../../resources/icons/chevron_down.svg';
import theme from '../../theme';

const useStyles = makeStyles(muiTheme => ({
  fab: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    zIndex: 9,
    backgroundColor: theme.color.secondary,
    transition: 'visibility 0.3s linear,opacity 0.3s linear',
    visibility: styleProps => (styleProps.visible ? 'visible' : 'hidden'),
    opacity: styleProps => (styleProps.visible ? '1' : '0'),
    '&:hover': {
      backgroundColor: theme.color.secondary
    }
  },
  arrow: {
    transform: 'rotate(180deg)',
    fill: theme.color.white
  }
}));

const BackToTop = () => {
  const [visible, setVisible] = useState(false);
  const { height } = useWindowSize();
  const styleProps = { visible };
  const classes = useStyles(styleProps);

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > height ? setVisible(true) : setVisible(false);
    };

    window.addEventListener('scroll', handleScroll);
  }, [height]);

  return (
    <Fab
      className={classes.fab}
      onClick={() =>
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    >
      <ChevronDown className={classes.arrow} />
    </Fab>
  );
};

export default BackToTop;
