import styled from 'styled-components';
import theme from '../../theme';
import { device } from '../../devices';

export default styled.div`
  flex: 1 0 33%;
  position: relative;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  min-width: 150px;
  max-width: 350px;
  margin-bottom: 24px;
  transition: all ${theme.transitionTime};

  &:hover {
    transform: scale(1.05);
  }

  @media ${device.tablet} {
    margin-left: 8px;
    margin-right: 8px;
  }

  & img {
    width: 100%;
    height: 200px;
    flex-shrink: 0;
    border-top-left-radius: ${theme.borderRadius}px;
    border-top-right-radius: ${theme.borderRadius}px;
  }

  & .body {
    flex: 1 0 auto;
    min-height: 1px;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    text-align: start;

    & button {
      margin-top: auto;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  & p {
    margin-top: 0px;
    margin-bottom: 24px;
  }
`;
