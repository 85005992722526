import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './reset.css';
import { Layout, ScrollToTop, PageNotFound } from './components';
import {
  LandingPage,
  AboutUs,
  Industrial,
  Contact,
  References,
  Activities,
  Project,
  Civil,
  Infrastructure
} from './pages';
import theme from './theme';
import { availablePaths } from './paths';

const Routes = props => {
  const activitiesPaths = availablePaths['activities'];
  const activitiesTypesPaths = [...activitiesPaths].map(
    activity => `${activity}/:type`
  );
  const allActivitiesPaths = [...activitiesPaths, ...activitiesTypesPaths];

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ScrollToTop />
      <Layout>
        <Switch>
          <Route
            exact
            path={availablePaths['landing']}
            component={LandingPage}
          />
          <Route exact path={availablePaths['about']} component={AboutUs} />
          <Route
            exact
            path={availablePaths['career']}
            render={props => <AboutUs {...props} isCareer />}
          />
          <Route exact path={availablePaths['contact']} component={Contact} />
          <Route
            exact
            path={availablePaths['references']}
            component={References}
          />
          <Route exact path={allActivitiesPaths} component={Activities} />
          <Route exact path={availablePaths['project']} component={Project} />
          <Route
            exact
            path={availablePaths['industrial']}
            component={Industrial}
          />
          <Route exact path={availablePaths['civil']} component={Civil} />
          <Route
            exact
            path={availablePaths['infrastructure']}
            component={Infrastructure}
          />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </Layout>
    </ThemeProvider>
  );
};

export default Routes;
