const color = {
  primary: '#123F73',
  secondary: '#ff932d',
  white: '#FFFFFF',
  grey: '#F2F2F2',
  greyLight: '#B4B8BC',
  blueLight: '#6a98c5',
  black: '#000000',
  orange: '#ff932d'
};

const navbarHeight = {
  mobile: 68,
  tablet: 80,
  laptop: 80
};

const sectionPadding = {
  mobile: 24,
  tablet: 48,
  laptop: 48
};

export default {
  color,
  borderRadius: 36,
  border: 'border: 1px solid rgba(0, 0, 0, 0.125)',
  transitionTime: '300ms',
  sectionPadding,
  navbarHeight,
  contentMaxWidth: 1400
};

export const revealTimeout = {
  initial: 200,
  next: 500
};
