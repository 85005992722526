import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import { device } from './devices';

export default createGlobalStyle`
* {
    border: 0;
    box-sizing: inherit;
    -webkit-font-smoothing: auto;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  @font-face {
    font-family: "Oranda";
    src: url("resources/fonts/oranda.tff") format("truetype");
  };

  @font-face {
    font-family: "Oranda-bold";
    src: url("resources/fonts/oranda-bold.tff") format("truetype");
  };

  html {
    display: flex;
    min-height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.5;
    color: #000000;
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: auto;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: 'Poppins', sans-serif, Helvetica, Arial;
    font-weight: 300;
  }

  body {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overscroll-behavior-y: none;
    -webkit-overflow-scrolling: touch;
  }

  b {
    font-weight: bold;
  }

  h1 {
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 600;
    margin-bottom: 24px;
    color: #123F73;
    position: relative;
    text-transform: uppercase;
    font-size: 2.375rem;

    @media ${device.tablet} {
      font-size: 48px;
      line-height: 54px;
    }
  }

  h2 {
    font-weight: 600;
    margin-bottom: 24px;
    font-family: 'Barlow Condensed', sans-serif;
    color: #123F73;
    position: relative;
    text-transform: uppercase;
    font-size: 2rem;

    @media ${device.tablet} {
      font-size: 40px;
      line-height: 46px;
    }
  }

  h3 {
    font-family: 'Poppins', serif;
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 24px;
  }

  #root {
    height: 100%;
    width: 100%;
  }

  a {
    color: inherit;
  }

  #SRLLightbox {
    z-index: 3;
  }

  .base-card {
    display: flex;
    flex-direction: column;
    border-radius: ${theme.borderRadius}px;
    background-clip: border-box;
    background-color: ${theme.color.white};
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-align: center;
    word-wrap: break-word;
  }

  .email {
    text-decoration: underline;
    word-wrap: break-word;
    text-align: left;
  }
  
  .phone {
    letter-spacing: 1.3px;
    word-wrap: break-word;
    text-align: left;
  }

  .label {
    font-size: 10px;
    letter-spacing: 1.5px;
    line-height: 16px;
    color: ${theme.color.greyLight};
    display: block;
    text-transform: uppercase;
    word-wrap: break-word;
    text-align: left;
    font-weight: bold;
  }

  .person-picture {
    display: block;
    height: 100px;
    width: auto;
    border-radius: 12px;
  }

  .contact {
    font-size: 14px;
    line-height: 22px;
    display: block;
  }

  & #SRLLightbox {
    z-index: 15 !important;
  }
`;
