import styled from 'styled-components';
import { FlexCol, FlexRow } from '../ui/globals';
import { device } from '../../devices';
import theme from '../../theme';

export const Container = styled(FlexCol)`
  padding-bottom: 24px;

  /* @media ${device.laptop} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  } */

  > .contact-person-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: ${theme.color.primary};

    @media ${device.mobileL} {
      width: 250px;
    }

    @media ${device.laptop} {
      flex-basis: 100%;
      width: 100%;
    }

    & .contact-person-button {
      margin-top: ${theme.sectionPadding.mobile}px;
      display: flex;
      justify-content: center;
      flex-basis: 100%;

      &.button-laptop {
        display: none;
      }

      @media ${device.laptop} {
        justify-content: flex-start;
        &.button-mobile {
          display: none;
        }

        &.button-laptop {
          display: flex;
        }
      }
    }
  }
`;

export const Person = styled(FlexRow)`
  & .name {
    margin-bottom: 0;
    text-align: left;
  }

  & .picture {
    margin-right: 16px;
    @media ${device.mobileL} {
      height: 150px;
    }

    @media ${device.laptop} {
      height: 250px;
      margin-right: 40px;
    }
  }
`;
