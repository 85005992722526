import styled from 'styled-components';
import theme from '../../../theme';
import { device } from '../../../devices';

export const NavbarContainer = styled.div`
  width: 100%;
  height: ${theme.navbarHeight.mobile}px;
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${theme.color.primary};
  color: ${theme.color.white};
  z-index: 10;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  & a {
    display: inline-block;
    position: relative;
  }

  .active-link {
    &:after {
      content: '';
      position: absolute;
      z-index: 99;
      left: 0;
      bottom: -4px;
      background: ${props => props.theme.color.white};
      height: 2px;
      width: 100%;
    }
  }

  & .content {
    height: 100%;
    width: 100%;
    max-width: ${theme.contentMaxWidth}px;
    margin: 0 auto;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: ${theme.sectionPadding.mobile}px;
    padding-right: ${theme.sectionPadding.mobile}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media ${device.tablet} {
      padding-left: ${theme.sectionPadding.tablet}px;
      padding-right: ${theme.sectionPadding.tablet}px;
    }
  }

  & .brand {
    margin: 0;
    padding: 0;

    img {
      width: 100px;
      height: auto;
      display: block;
      cursor: pointer;
    }
    @media ${device.tablet} {
      img {
        width: 160px;
      }
    }
  }

  @media ${device.tablet} {
    height: ${theme.navbarHeight.tablet}px;
    & .brand {
      img {
        width: 160px;
      }
    }
  }
`;
