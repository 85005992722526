import React from 'react';
import parse from 'html-react-parser';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';

const useStyles = makeStyles({
  root: {
    color: '#000000',
    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.1)'
  },
  valueCell: {
    fontFamily: 'Poppins, sans-serif, Helvetica, Arial'
  },
  propertyCell: {
    fontFamily: 'Poppins, sans-serif, Helvetica, Arial'
  }
});

export default function SimpleTable(props) {
  const { translation } = props;
  const classes = useStyles();

  const isKeyEmpty = key => key === '';

  return (
    <TableContainer className={classes.root} component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          {translation.map((row, id) => (
            <TableRow key={id}>
              {!isKeyEmpty(row.key) ? (
                <>
                  <TableCell
                    className={classes.propertyCell}
                    component="th"
                    scope="row"
                  >
                    {row.key}:
                  </TableCell>
                  <TableCell className={classes.valueCell} align="right">
                    {parse(row.val)}
                  </TableCell>
                </>
              ) : (
                <TableCell
                  className={classes.valueCell}
                  align="left"
                  colSpan={2}
                >
                  {parse(row.val)}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
