import React from 'react';
import styled from 'styled-components';
import EnumCard from './enum-card/EnumCard';
import { FlexCol } from '../ui/globals';
import { device } from '../../devices';
import Reveal from '../reveal/Reveal';
import { revealTimeoutNext } from '../../utils';

const Container = styled(FlexCol)`
  @media ${device.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
  }
`;

const EnumCards = props => {
  const { data, cardColor } = props;

  return (
    <Container>
      {Object.values(data).map((cardValue, index) => (
        <Reveal delay={revealTimeoutNext(index)} flex key={index}>
          <EnumCard
            title={cardValue.title}
            list={cardValue.list}
            key={index}
            bgColor={cardColor}
          />
        </Reveal>
      ))}
    </Container>
  );
};

export default EnumCards;
