import React from 'react';
import styled from 'styled-components';
import theme from '../../../../../theme';
import { device } from '../../../../../devices';

const Container = styled.div`
  color: ${theme.color.white};

  & > div {
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > span {
      position: relative;
      display: block;
      width: 25px;
      height: 2px;
      background-color: ${theme.color.white};
      transition: all ease-in-out 0.2s;
    }
  }

  .open span:nth-child(2) {
    opacity: 0;
  }

  .open span:nth-child(3) {
    transform: rotate(45deg);
    top: -9px;
  }

  .open span:nth-child(1) {
    transform: rotate(-45deg);
    top: 9px;
  }

  @media ${device.laptop} {
    display: none;
  }
`;

const BurgerMenu = props => {
  const { navbarState, onClick } = props;
  return (
    <Container onClick={onClick}>
      <div className={navbarState ? 'open' : ''}>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
      </div>
    </Container>
  );
};

export default BurgerMenu;
