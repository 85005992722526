const paths = {
  landing: {
    sk: '/',
    en: '/',
    nl: '/',
    de: '/'
  },
  about: {
    sk: '/o-nas',
    en: '/about-us',
    nl: '/over-ons',
    de: '/uber-uns'
  },
  services: {
    sk: '/sluzby',
    en: '/services',
    nl: '/diensten',
    de: '/leistungen'
  },
  contact: {
    sk: '/kontakt',
    en: '/contact',
    nl: '/contact',
    de: '/kontakt'
  },
  industrial: {
    sk: '/sluzby/priemyselne-stavby',
    en: '/services/industrial-buildings',
    nl: '/diensten/industriele-gebouwen',
    de: '/leistungen/industriebauten'
  },
  civil: {
    sk: '/sluzby/obcianske-stavby',
    en: '/services/civil-buildings',
    nl: '/diensten/civiele-gebouwen',
    de: '/leistungen/gesellschaftsbauten'
  },
  infrastructure: {
    sk: '/sluzby/infrastruktura',
    en: '/services/infrastructure',
    nl: '/diensten/infrastructuur',
    de: '/leistungeng/infrastruktur'
  },
  activities: {
    sk: '/aktivity',
    en: '/activities',
    nl: '/activiteiten',
    de: '/aktivitaeten'
  },
  staticCalculations: {
    sk: '/aktivity/staticke-vypocty',
    en: '/activities/static-calculations',
    nl: '/activiteiten/structurele-analyse',
    de: '/aktivitaeten/statische-berechnungen'
  },
  complexProjects: {
    sk: '/aktivity/komplexne-projekty',
    en: '/activities/complex-projects',
    nl: '/activiteiten/complexe-projecten',
    de: '/aktivitaeten/komplexe-projekte'
  },
  counseling: {
    sk: '/aktivity/poradenstvo-pre-developer',
    en: '/activities/counseling-for-developers',
    nl: '/activiteiten/advies-voor-ontwikkelaars',
    de: '/aktivitaeten/beratung-fur-developer'
  },
  concreteConstructions: {
    sk: '/aktivity/prefabrikovane-betonove-konstrukcie',
    en: 'activities/concrete-constructions',
    nl: '/activiteiten/beton-constructies',
    de: '/aktivitaeten/betonkonstruktionen'
  },
  steelConstructions: {
    sk: '/aktivity/ocelove-konstrukcie',
    en: '/activities/steel-constructions',
    nl: '/activiteiten/stalen-constructies',
    de: '/aktivitaeten/stahlkonstruktionen'
  },
  specialServices: {
    sk: '/aktivity/sluzby-specialistov',
    en: '/activities/special-services',
    nl: '/activiteiten/gespecialiseerde-diensten',
    de: '/aktivitaeten/leistungen-fur-spezialisten'
  },
  project: {
    sk: '/projekt/:projectId',
    en: '/project/:projectId',
    nl: '/project/:projectId',
    de: '/projekt/:projectId'
  },
  references: {
    sk: '/referencie',
    en: '/references',
    nl: '/referenties',
    de: '/referenzen'
  },
  career: {
    sk: '/o-nas/kariera',
    en: '/about-us/career',
    nl: '/over-ons/carriere',
    de: '/karriere'
  }
};

export default paths;

export const availablePaths = Object.keys(paths).reduce((acc, pathId) => {
  acc[pathId] = Object.values(paths[pathId]).flat();
  return acc;
}, {});

export const getPathId = url => {
  return Object.keys(availablePaths).find(pathId => {
    return availablePaths[pathId].includes(url);
  });
};
