import theme, { revealTimeout } from '../theme';

export const importAll = r => {
  return r.keys().map(r);
};

export const importFirst = r => {
  return importAll(r)[0];
};

export const getProjectsByService = (projects, serviceType) => {
  return projects.filter(proj => proj.service === serviceType);
};

export const scrollToRef = ref => {
  if (ref.current !== null) {
    window.scrollTo({
      left: 0,
      top: ref.current.offsetTop - theme.navbarHeight.laptop,
      behavior: 'smooth'
    });
  }
};

export const revealTimeoutNext = index => {
  return revealTimeout.initial + index * revealTimeout.next;
};
