import React from 'react';
import Navbar from './header/Navbar';
import Footer from './footer/Footer';

const Layout = props => {
  return (
    <>
      <Navbar />
      {props.children}
      <Footer />
    </>
  );
};

export default Layout;
