import styled from 'styled-components';
import theme from '../../../theme';
import { device } from '../../../devices';

export default styled.div`
  position: relative;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  width: 250px;
  max-width: 350px;
  cursor: pointer;
  transition: box-shadow ${theme.transitionTime};

  @media ${device.tablet} {
    width: 300px;
  }

  @media ${device.laptopL} {
    flex-basis: 33%;
    max-width: 400px;
  }

  &:hover {
    box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
      0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  }

  & .project-image-container {
    height: 120px;

    @media ${device.tablet} {
      height: 150px;
    }

    @media ${device.laptopL} {
      height: 200px;
    }

    & .project-image {
      height: 100%;
      width: 100%;
      background-image: ${props => `url(${props.image})`};
      background-position: ${props =>
        props.imagePosition ? props.imagePosition : 'center center'};
      background-size: cover !important;
      background-repeat: no-repeat;
    }
  }

  & .project-info {
    padding: 8px;
    padding-left: 16px;
    margin: 0;
    background-color: ${theme.color.white};
    text-align: left;

    @media ${device.tablet} {
      padding: 16px;
      height: 140px;
    }

    & .project-service {
      display: block;
      font-weight: 400;

      @media ${device.tablet} {
        font-size: 14px;
        line-height: 20px;
      }
    }

    & .project-title {
      display: block;
      font-family: 'Barlow Condensed', sans-serif;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;

      @media ${device.tablet} {
        font-size: 24px;
        line-height: 28px;
      }
    }

    & .project-location {
      display: block;
      color: ${theme.color.greyLight};
      font-weight: bolder;
      font-size: 10px;
      margin-top: 4px;

      @media ${device.tablet} {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`;
