import styled from 'styled-components';
import { device } from '../../../devices';
import theme from '../../../theme';

export const Container = styled.div`
  background-color: ${theme.color.black};
  color: ${theme.color.greyLight};
`;

export const Content = styled.div`
  width: 100%;
  max-width: ${theme.contentMaxWidth}px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: ${theme.sectionPadding.mobile}px;
  padding-right: ${theme.sectionPadding.mobile}px;

  @media ${device.tablet} {
    flex-direction: row;
    align-items: flex-start;
    padding-left: ${theme.sectionPadding.tablet}px;
    padding-right: ${theme.sectionPadding.tablet}px;
    justify-content: space-between;

    flex-wrap: wrap;

    & > .footer-item {
      margin-top: 32px;
    }
  }

  & > .footer-item:not(:first-child) {
    margin-top: 32px;
  }

  & .footer-item {
    flex-basis: 100%;
    text-align: center;
    width: 100%;

    @media ${device.tablet} {
      flex-basis: 30%;
      max-width: 30%;
      text-align: left;
    }

    @media ${device.laptop} {
      flex-basis: 25%;
      max-width: 200px;
    }

    & .title {
      color: ${theme.color.white};
      margin-bottom: 16px;
    }

    & ul.list {
      list-style: none;

      > li {
        margin-bottom: 8px;

        > a:hover {
          color: ${theme.color.white};
        }
      }

      > li.address-margin {
        margin-bottom: 16px;
      }
    }
  }

  & .social {
    @media ${device.tablet} {
      margin-right: auto;
    }
    @media ${device.laptop} {
      margin-right: 0;
      margin-lef: 0;
    }

    > ul {
      margin-left: -16px;
      > li {
        display: inline;
        margin-left: 16px;
      }
    }
  }

  & .social-icon {
    fill: ${theme.color.greyLight};

    &:hover {
      fill: ${theme.color.white};
    }
  }

  & .copyright {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    text-align: center;
    padding-top: 36px;
  }
`;
