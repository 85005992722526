import React from 'react';
import { ReactComponent as BridgeIcon } from '../../resources/icons/activities/bridge.svg';
import { ReactComponent as ComplexProjectsIcon } from '../../resources/icons/activities/complex_projects.svg';
import { ReactComponent as CounselingIcon } from '../../resources/icons/activities/counseling.svg';
import { ReactComponent as ReainforcementBuildingIcon } from '../../resources/icons/activities/reinforcement_building.svg';
import { ReactComponent as SeismicWaveIcon } from '../../resources/icons/activities/seismic_wave.svg';
import { ReactComponent as SteelBeamIcon } from '../../resources/icons/activities/steel_beam.svg';

export default {
  staticCalculations: 'staticCalculations',
  complexProjects: 'complexProjects',
  counseling: 'counseling',
  concreteConstructions: 'concreteConstructions',
  steelConstructions: 'steelConstructions',
  specialServices: 'specialServices'
};

export const activityCards = [
  {
    type: 'staticCalculations',
    icon: <BridgeIcon />
  },
  {
    type: 'complexProjects',
    icon: <ComplexProjectsIcon />
  },
  {
    type: 'steelConstructions',
    icon: <SteelBeamIcon />
  },
  {
    type: 'concreteConstructions',
    icon: <ReainforcementBuildingIcon />
  },
  {
    type: 'specialServices',
    icon: <SeismicWaveIcon />
  },
  {
    type: 'counseling',
    icon: <CounselingIcon />
  }
];
