import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';
import theme from '../../theme';
import { device } from '../../devices';
import { ReactComponent as CloseIcon } from '../../resources/icons/close.svg';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    position: 'relative',
    backgroundColor: theme.color.white,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    padding: '2rem',
    [`@media ${device.tablet}`]: {
      padding: '4.5rem'
    }
  },
  link: {
    textDecoration: 'underline',
    color: theme.color.secondary,
    marginTop: '1rem',
    display: 'block'
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer'
  },
  careerText: {
    fontSize: '1.25rem'
  },
  mt: {
    marginTop: '0.75rem'
  },
  h2: {
    textAlign: 'center'
  }
}));

export default function CareerModal() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MuiModal
      aria-labelledby="career-modal-title"
      aria-describedby="career-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      closeAfterTransition
      keepMounted
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 className={classes.h2} id="transition-modal-title">
            Hľadáme
          </h2>
          <ul>
            <li
              id="transition-modal-description"
              className={classes.careerText}
            >
              Projektanta REVIT/Allplan betón
            </li>
          </ul>
          <a
            className={classes.link}
            href="https://www.profesia.sk/praca/de-bondt/O4829306?search_id=064e2b5f-50d9-44bb-93fc-74f314091973"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.profesia.sk/praca/de-bondt/O4829306
          </a>
          <IconButton className={classes.closeIcon} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </Fade>
    </MuiModal>
  );
}
