import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container, { VideoContainer, ImageContainer } from './styles';
import { ScrollDown } from '../../../components';
import { tabletWidth } from '../../../devices';
import { useWindowSize } from '../../../hooks';
import video from '../../../resources/landing/video.mp4';
import mobileImage from '../../../resources/landing/civil.jpg';
import desktopImage from '../../../resources/landing/desktop.jpg';

function LandingVideo(props) {
  const { aboutSectionRef } = props;
  const [showVideo, setShowVideo] = useState(false);
  const { width } = useWindowSize();
  const { t } = useTranslation();

  useEffect(() => {
    width < tabletWidth ? setShowVideo(false) : setShowVideo(true);
  }, [width]);

  return (
    <Container>
      {showVideo ? (
        <VideoContainer image={desktopImage}>
          <video autoPlay loop muted>
            <source src={video} type="video/mp4" />
          </video>
        </VideoContainer>
      ) : (
        <ImageContainer>
          <img src={mobileImage} alt="riviera" />
        </ImageContainer>
      )}
      <div className="content">
        <span className="title">De Bondt</span>
        <span className="subtitle">{t('pages.landing.video.subTitle')}</span>
      </div>
      <ScrollDown sectionRef={aboutSectionRef} />
    </Container>
  );
}

export default LandingVideo;
