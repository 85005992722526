import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const HeadInfo = props => {
  const { pathId } = props;
  const { t } = useTranslation();
  const headProperty = pathId || 'landing';

  const formatTitle = title => {
    return title.charAt(0).toUpperCase() + title.slice(1);
  };

  return (
    <Helmet>
      <title>
        {formatTitle(t(`components.head.${headProperty}.title`))} | De Bondt
      </title>
      <meta
        name="description"
        content={t(`components.head.${headProperty}.desc`)}
      />
    </Helmet>
  );
};

export default HeadInfo;
