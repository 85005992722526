import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

const Map = withScriptjs(
  withGoogleMap(props => {
    const { isMarkerShown, markers } = props;
    return (
      <GoogleMap defaultZoom={8} defaultCenter={markers.tn} center={markers.tn}>
        {isMarkerShown && (
          <>
            {Object.keys(markers).map(marker => (
              <Marker position={markers[marker]} key={marker} />
            ))}
          </>
        )}
      </GoogleMap>
    );
  })
);

export default Map;
