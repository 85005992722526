import zilina from '../../resources/offices/zilina.jpg';
import trencin from '../../resources/offices/trencin.jpg';
import trnava from '../../resources/offices/trnava.jpg';
import tnOffice from '../../resources/contact/tn.JPG';
import ttOffice from '../../resources/contact/tt.JPG';
import zaOffice from '../../resources/contact/za.JPG';
import deOffice from '../../resources/contact/conTura.JPG';

export default {
  tn: {
    title: 'Hlavné sídlo Trenčín',
    phone: '+421 (32) 748 00 11',
    email: 'info@debondt.sk',
    address: 'Rybárska 7389',
    postcode: '911 01',
    city: 'Trenčín',
    marker: {
      lat: 48.889491,
      lng: 18.028728
    },
    cityImage: trencin,
    officeImage: tnOffice
  },
  za: {
    title: 'Pobočka Žilina',
    phone: '+421 903 551 560',
    email: 'jan.slivka@debondt.sk',
    address: 'Rosinská 8',
    postcode: '010 08',
    city: 'Žilina',
    marker: {
      lat: 49.198156,
      lng: 18.768251
    },
    cityImage: zilina,
    officeImage: zaOffice
  },
  tt: {
    title: 'Pobočka Trnava',
    phone: '+421 911 329 430',
    email: 'roman.stefkovic@debondt.sk',
    address: 'Nám. Jozefa Herdu 1',
    postcode: '917 01',
    city: 'Trnava',
    marker: {
      lat: 48.383413,
      lng: 17.590435
    },
    cityImage: trnava,
    officeImage: ttOffice
  },
  potsdam: {
    title: 'Con-tura Planungsgesellschaft mbH',
    phone: '+49 (0) 331-60 122 691',
    email: 'info@con-tura.de',
    address: 'Gutenbergstrasse 82',
    postcode: '14467',
    city: 'Potsdam',
    marker: {
      lat: 52.402018,
      lng: 13.059103
    },
    officeImage: deOffice
  }
};
