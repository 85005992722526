import React, { useRef } from 'react';
import Video from './video/LandingVideo';
import About from './about/About';
import DebondtInNumbers from './debondt-in-numbers/DebondtInNumbers';
import Services from './services/Services';
import Activities from './activities/Activities';
import Location from './location/Location';
import { Section, Reveal, HeadInfo } from '../../components';

const LandingPage = props => {
  const aboutSectionRef = useRef(null);

  return (
    <>
      <HeadInfo pathId="landing" />
      <Video aboutSectionRef={aboutSectionRef} />
      <Reveal>
        <Section>
          <About sectionRef={aboutSectionRef} />
        </Section>
        <Section>
          <DebondtInNumbers />
        </Section>
        <Section>
          <Services />
        </Section>
        <Section>
          <Activities />
        </Section>
        <Section>
          <Location />
        </Section>
      </Reveal>
    </>
  );
};

export default LandingPage;
