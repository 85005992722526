import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  FlexCol,
  TitleUnderlined,
  PageLayout,
  Section,
  OfficeCard,
  Reveal,
  HeadInfo
} from '../../components';
import MapContainer from './map-container/MapContainer';
import topBg from '../../resources/contact/kontakt.jpg';
import { offices } from '../../data';

const OfficeContainer = styled(FlexCol)`
  margin-top: 8px;
`;

const Contact = () => {
  const { t } = useTranslation();

  const makeMarkers = () => {
    return Object.keys(offices).reduce((acc, city) => {
      acc[city] = offices[city].marker;
      return acc;
    }, {});
  };

  return (
    <>
      <HeadInfo pathId="contact" />
      <PageLayout title={t('pages.contact.top.title')} image={topBg}>
        <Section>
          <Reveal>
            <TitleUnderlined>{t('pages.contact.title')}</TitleUnderlined>
          </Reveal>
          <OfficeContainer>
            {Object.keys(offices).map((office, index) => (
              <Reveal flex key={office}>
                <OfficeCard reverse={index % 2} data={offices[office]} />
              </Reveal>
            ))}
          </OfficeContainer>
        </Section>
        <Section paddingBottom="0px" paddingTop="0px" fullWidth>
          <MapContainer markers={makeMarkers()} />
        </Section>
      </PageLayout>
    </>
  );
};

export default Contact;
