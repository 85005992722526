import React from 'react';
import Container from './styles';
import { useTranslation } from 'react-i18next';
import { importFirst } from '../../../utils';
import { useHref } from '../../../hooks';
import { NavLink } from 'react-router-dom';

const ProjectCard = props => {
  const { project } = props;
  const href = useHref('project', { projectId: project.name });
  const { t } = useTranslation();

  return (
    <Container image={importFirst(project.thumbnail)} as={NavLink} to={href}>
      <div className="project-image-container">
        <div className="project-image" />
      </div>
      <div className="project-info">
        <span className="project-service label">
          {t(`services.${project.service}`)}
        </span>
        <span className="project-title">
          {t(`${project.translation}.title`)}
        </span>
        <span className="project-location">
          {t(`locations.${project.location}`)}
        </span>
      </div>
    </Container>
  );
};

export default ProjectCard;
