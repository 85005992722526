import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Person } from './styles';
import { Button } from '../../components';

const ContantPerson = props => {
  const { personData, personId } = props;
  const { picture, name, phone, email } = personData;
  const { t } = useTranslation();

  const handleClick = email => {
    window.location.assign(`mailto:${email}`);
  };

  const formatPosition = () => {
    const position = t(`components.team.roles.${personId}`);
    if (position.includes(',')) {
      return position.substr(0, position.indexOf(','));
    }
    return position;
  };

  return (
    <Container>
      <h1>{t('components.contactPerson.title')}</h1>
      <div className="contact-person-content">
        <Person>
          <img
            className="picture person-picture"
            src={picture}
            alt={`${name}`}
          />
          <div className="info">
            <h3 className="name">{name}</h3>
            <p className="position label">{formatPosition()}</p>
            <p className="contact">{email}</p>
            <p className="contact">{phone}</p>
            <div className="contact-person-button button-laptop">
              <Button onClick={() => handleClick(email)}>
                {t('components.contactPerson.button')}
              </Button>
            </div>
          </div>
        </Person>
        <div className="contact-person-button button-mobile">
          <Button onClick={() => handleClick(email)}>
            {t('components.contactPerson.button')}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default ContantPerson;
