import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import { device } from '../../devices';

const StyledLine = styled.span`
  display: none;
  background-color: ${props => props.color || theme.color.secondary};
  position: relative;
  width: 55px;
  height: 3px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};

  @media ${device.tablet} {
    display: block;
  }

  ${props =>
    props.fullWidth && {
      width: '100%'
    }}

  ${props =>
    props.displaySm && {
      display: 'block'
    }}

  ${props =>
    props.likeTitle && {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '24px'
    }}
`;

const Line = props => {
  const { showAlways, likeTitle } = props;
  return (
    <StyledLine className="line" displaySm={showAlways} likeTitle={likeTitle}>
      &nbsp;
    </StyledLine>
  );
};

export default Line;
