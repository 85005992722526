import styled from 'styled-components';
import theme from '../../theme';
import { device } from '../../devices';

export const Container = styled.div`
  position: relative;
  width: 250px;
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  padding-left: 16px;

  @media ${device.tablet} {
    width: 380px;
    max-width: 380px;
    flex-basis: 100%;
    align-self: stretch;
  }

  @media ${device.laptop} {
    flex-basis: auto;
  }

  & .picture {
    @media ${device.tablet} {
      height: 140px;
    }
  }

  & .info {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    word-break: break-word;
    margin-right: auto;
    width: 100%;
    color: ${theme.color.primary};
  }

  & .name {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 4px;
    overflow: auto;
    word-wrap: break-word;
    text-align: start;
  }
`;
