import React from 'react';
import {
  PageLayout,
  Section,
  TitleUnderlined,
  MainText,
  Projects,
  EnumCards,
  Reveal,
  HeadInfo
} from '../../../components';
import bgImage from '../../../resources/services/modernizacja.jpg';
import { getProjectsByService } from '../../../utils';
import { projects } from '../../../data';
import { useTranslation } from 'react-i18next';

const Industrial = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeadInfo pathId="industrial" />
      <PageLayout
        image={bgImage}
        title={t('pages.industrial.top.title')}
        description={t('pages.industrial.top.desc')}
      >
        <Reveal>
          <Section>
            <TitleUnderlined disableTitle lineShowAlways />
            <MainText>{t('pages.industrial.mainText')}</MainText>
          </Section>
          <Section paddingTop="0px">
            <EnumCards
              data={t('pages.industrial.enumCards', { returnObjects: true })}
            />
          </Section>
          <TitleUnderlined>{t('components.projects.title')}</TitleUnderlined>
        </Reveal>
        <Reveal fraction={0}>
          <Projects projects={getProjectsByService(projects, 'industrial')} />
        </Reveal>
      </PageLayout>
    </>
  );
};

export default Industrial;
