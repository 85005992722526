import React from 'react';
import { useRedirectTo } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Office from './Office';
import { device } from '../../../devices';
import { TitleUnderlined, FlexCol, Reveal } from '../../../components';
import { offices } from '../../../data';
import { revealTimeoutNext } from '../../../utils';

const Container = styled(FlexCol)`
  & .offices-container {
    display: flex;
    flex-direction: column;

    @media ${device.tablet} {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`;

const Location = props => {
  const redirect = useRedirectTo();
  const { t } = useTranslation();

  return (
    <Container>
      <Reveal>
        <TitleUnderlined>{t('pages.landing.location.title')}</TitleUnderlined>
      </Reveal>
      <div className="offices-container mt-md">
        {Object.keys(offices)
          .filter(city => city !== 'potsdam')
          .map((city, index) => (
            <Reveal delay={revealTimeoutNext(index)} flex key={index}>
              <Office
                key={city}
                image={offices[city].cityImage}
                city={offices[city].city}
                street={offices[city].address}
                postCode={offices[city].postcode}
                email={offices[city].email}
                phoneNumber={offices[city].phone}
                handleClick={() => redirect('contact')}
              />
            </Reveal>
          ))}
      </div>
    </Container>
  );
};

export default Location;
