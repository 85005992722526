import React from 'react';
import styled from 'styled-components';
import { device } from '../../devices';
import theme from '../../theme';

const Container = styled.div`
  margin-top: ${theme.navbarHeight.mobile}px;

  @media ${device.tablet} {
    margin-top: ${theme.navbarHeight.tablet}px;
  }

  @media ${device.laptop} {
    margin-top: ${theme.navbarHeight.laptop}px;
  }
`;

const PageLayoutWithoutImage = props => {
  return <Container>{props.children}</Container>;
};

export default PageLayoutWithoutImage;
