import React from 'react';
import styled from 'styled-components';
import { device } from '../../devices';
import theme from '../../theme';

const Container = styled.span`
  font-size: 10px;
  letter-spacing: 1.5px;
  color: ${theme.color.blueLight};
  display: block;
  text-transform: uppercase;
  word-wrap: break-word;
  text-align: center;
  font-weight: bold;

  margin-bottom: ${props => (props.mb ? '40px' : 0)};

  @media ${device.tablet} {
    font-size: 14px;
  }

  @media ${device.laptop} {
    ${props =>
      props.laptopLeft && {
        textAlign: 'left'
      }}
  }
`;

const CategoryLabel = props => {
  const { children, laptopLeft, mb } = props;
  return (
    <Container mb={mb} laptopLeft={laptopLeft}>
      {children}
    </Container>
  );
};

export default CategoryLabel;
